#myinfo{margin-top: 64px;}
#myinfo h4{font-size: 20px; font-weight: 700; margin-bottom: 24px; text-align: left;}
#myinfo > a{margin: 24px 0;}
#myinfo .mobileBox{overflow: hidden;}
#myinfo .mobileBox .middleTitle{font-size: 16px; margin-bottom: 16px; font-weight: 400;}
#myinfo .mobileBox .inputBox{margin-bottom: 40px;}
#myinfo .mobileBox .inputBox > ul > li{margin-bottom: 16px;}
#myinfo .mobileBox .inputBox > ul > li:last-child{margin-bottom: 0;}
#myinfo .mobileBox .inputBox > ul > li > p{font-size: 12px;}
#myinfo .mobileBox .inputBox > ul > li > p:first-child{margin-bottom: 8px; color:#999;}
#myinfo .mobileBox .inputBox > ul > li.infoText > p:nth-child(1){font-size: 14px; color:#222;}
#myinfo .mobileBox .inputBox > ul > li.infoText > ul > li{list-style: disc; font-size: 12px; color:#666; margin-left: 16px;}
#myinfo .mobileBox .inputBox .ibsInfo > input{margin-bottom: 4px;}
#myinfo .mobileBox .inputBox .ibsInfo > span{font-size: 12px; color:#999; margin-bottom: 16px; display: block; padding-left: 16px;}
#myinfo .mobileBox .inputBox .ibsInfo > span.colRed{color:#FB5555;}
#myinfo .mobileBox .inputBox .ibsInfo > .twoInput{display: flex; gap:10px; margin-bottom: 4px;}
#myinfo .mobileBox .inputBox .ibsInfo > .phoneNum{display: grid; gap:16px; grid-template-columns: auto 106px;}

/* 정보수신동의 */
#myinfo .consent{border:1px solid #EAEAEA; border-radius: 16px;}
#myinfo .consent .agreeAll{padding: 16px; border-bottom: 1px solid #EAEAEA;}
#myinfo .consent .termsBox{justify-content: space-between; align-items: center; display: flex;}
#myinfo .consent .agreeAll > .termsBox > span{font-size: 16px; font-weight: 400;}
#myinfo .consent .agree:first-child{padding: 16px 16px 8px 16px;}
#myinfo .consent .agree:last-child{padding: 8px 16px 16px 16px;}
#myinfo .consent .imageCheckbox {display: flex; align-items: center}
#myinfo .consent .imageCheckbox > p{color:#666;}
#myinfo .consent .imageCheckbox img.checkbox {width: 24px; height: 24px; margin-right: 16px; cursor: pointer;}
#myinfo .postscript{font-size: 12px; color:#666; margin: 16px 0 24px 0;}
#myinfo .mobileBox > div{overflow: hidden;}
#myinfo .withdrawalBtn{display: inline; float: right; color:#999; border-bottom: 1px solid #999; margin-top: 32px;}

@media all and (max-width:767px){
    #myinfo > .mobileBox h4{display:none;}
}