#withdrawal{margin-top: 64px;}
#withdrawal h4{font-size: 20px; font-weight: 700; margin-bottom: 24px; text-align: left;}
#withdrawal > .mobileBox > div > p{font-size: 16px;}
#withdrawal > .mobileBox > div > p:nth-child(3){margin-bottom: 8px; }
#withdrawal > .mobileBox > div > ul > li{list-style: disc; color:#666; margin-left: 16px; letter-spacing: -0.5px; font-size: 12px; margin-bottom: 5px;}
#withdrawal > .mobileBox > div > ul > li > span{font-size: 12px;}
#withdrawal > .mobileBox > div > textarea{margin:16px 0 24px 0; padding: 10px 12px;width: 330px; height: 100px; padding: 10px;}

#withdrawal .imageCheckbox{display: flex; gap:16px; align-items: center; margin: 16px 0 24px 0;}
#withdrawal .imageCheckbox img.checkbox {width: 24px; height: 24px; cursor: pointer;}
#withdrawal .imageCheckbox > p{color:#222;}

@media all and (max-width:767px){
    #withdrawal > .mobileBox{width: 100%;}
    #withdrawal > .mobileBox h4{display:none;}
    #withdrawal > .mobileBox > div > textarea{width: 100%;}
}