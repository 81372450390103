#pwInquiry{margin-top: 64px;}
#pwInquiry > .mobileBox h4{font-size: 20px; font-weight: 700; margin-bottom: 16px;}
#pwInquiry > .mobileBox > a{margin: 24px 0; padding:0;}

#pwInquiry .mobileBox .inputBox > ul > li{margin-bottom: 16px;}
#pwInquiry .mobileBox .inputBox > ul > li > p{font-size: 12px;}
#pwInquiry .mobileBox .inputBox .ibsInfo{position: relative;}
#pwInquiry .mobileBox .inputBox .ibsInfo > input{margin-bottom: 4px;}
#pwInquiry .mobileBox .inputBox .ibsInfo > span{font-size: 12px; color:#999; margin-bottom: 16px; display: block; padding-left: 16px; letter-spacing: -0.5px;}
#pwInquiry .mobileBox .inputBox .ibsInfo > span.timeLimit{position: absolute; right:14px; top:42px; color:#222; font-weight: 700; }
#pwInquiry .mobileBox .inputBox .ibsInfo > span.colRed{color:#FB5555;}
#pwInquiry .mobileBox .inputBox .ibsInfo > .phonBox .btnPointLine > span{color:#6042F9; font-weight: 700;}
#pwInquiry .mobileBox .inputBox .ibsInfo > .phonBox{display: grid; gap:10px; grid-template-columns: 2fr 114px; margin-bottom: 4px;}
#pwInquiry .mobileBox .inputBox .ibsInfo > .phonBox > a{font-size: 14px;}

#pwInquiry > .mobileBox > .joinBox{display:flex; justify-content: center;}
#pwInquiry > .mobileBox > .joinBox > li::after{display: inline-block; content: ''; width:1px; height: 12px; background-color: #D9D9D9; }
#pwInquiry > .mobileBox > .joinBox > li:last-child::after{display: none;}
#pwInquiry > .mobileBox > .joinBox > li{text-align: center; }
#pwInquiry > .mobileBox > .joinBox > li > a{display: inline-block;  color:#999; padding: 8px 16px;}
#pwInquiry > .mobileBox > .joinBox > li > a.join{color:#6042F9; font-weight: 700;}
@media all and (max-width:1023px){
    #pwInquiry .mobileBox .inputBox .ibsInfo > span br{display: block;}
}
@media all and (max-width:767px){
    #pwInquiry > .mobileBox h4{display:none;}
}