#calculate{padding-top: 40px;}
#calculate > .subTitle > p:nth-child(2){font-size: 16px; color:#222; margin-bottom: 8px;}
#calculate > .excelDown{display: flex; justify-content: space-between; margin-bottom: 24px;}
#calculate > .excelDown > select{width:320px;}
#calculate > .tableList td.Outstanding{color:#FB5555;}

.subTitle{position: relative;}
#calculate > .subTitle > p{color:#222; font-size: 16px; line-height: 24px;}
.helpBox{position: absolute; display: flex; gap:4px; left:101px; top:0; align-items: center;}
.helpBox > a{width: 24px; display: block;}
.helpBox > img{width: 218px; height: 22px;}

#calculate .layerCont .scrollBox{height: 403px;}
#calculate .layerCont{padding: 24px;}
#calculate .layerCont select{margin-bottom: 16px;}
#calculate .layerCont select{margin-bottom: 24px; width:100%;}
#calculate .layerCont .firstText{margin-bottom: 16px; color:#666; line-height: 22px;}
#calculate .layerCont .prTitle{font-weight: 700; font-size: 15px; margin-bottom: 4px;}
#calculate .layerCont .prsubTitle{font-size: 16px;}
#calculate .layerCont .subText{color:#666; line-height: 22px; margin-bottom: 16px;}
#calculate .layerCont .subText:last-child{margin-bottom: 0;}
#calculate .layerCont .subText.mgB8{margin-bottom: 8px;}
#calculate .layerCont .subText > span{color:#666; padding-left: 16px; display: block; margin-top: 8px;}
#calculate .layerCont .subList{margin-bottom: 16px; list-style: disc outside; padding-left: 20px;}
#calculate .layerCont .subList > li{font-size: 14px; color:#666; line-height: 18px; letter-spacing: -.5px; }



/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
    .tableBox{overflow-y: scroll; -ms-overflow-style: none; scrollbar-width: none;}
    .tableBox::-webkit-scrollbar {display: none; /* 크롬, 사파리, 오페라 */}
    .tableBox > table{min-width: 944px;}
	.tableBox thead > tr > th{padding: 15px 8px;}
	.tableBox tbody > tr > td:first-child, .tableBox thead > tr:first-child > th:first-child{position: sticky; left: 0; border-right: 1px solid #eaeaea; }
	
}

@media all and (max-width:767px){
    #calculate > .excelDown{display: block;}
    #calculate > .excelDown > a{width:100%;}
    #calculate > .excelDown > select{width: 100%; margin-bottom: 16px;}
    .helpBox > img{display: none;}
    #calculate .layerPop{left: 0; background-color: none;}
    #calculate .layerPop .layerCon{width: 100%; height: 100%; border-radius: 0; position: unset; top:0; left:0; transform: translate(0); max-width: none; }
    #calculate .layerPop .layerCont{padding: 16px 20px;}
    #calculate .layerPop .layerCont .scrollBox{height: calc(100vh - 40px);}
    #calculate .layerCont .subText:last-child{margin-bottom: 170px;}
}