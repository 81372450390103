#recommend{padding-top: 40px;}
#recommend > .excelDown{text-align: right;}
#recommend .addBox{border:1px solid #dadada; box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; padding: 24px 16px; display: grid; grid-template-columns: 1fr 1fr 1fr; margin: 24px 0 32px 0;}
#recommend .addBox > li{text-align: center;}
#recommend .addBox > li > p:nth-child(1){color:#999; margin-bottom: 8px;}
#recommend .addBox > li > p:nth-child(2){font-size: 18px; font-weight: 700;}
/* ****************** 반응형 ********************** */
@media all and (max-width:767px){
    #recommend > .excelDown > a{width: 100%;}
    #recommend .addBox{display: block;}
    #recommend .addBox > li{display: flex; justify-content: space-between; padding: 16px;}
}