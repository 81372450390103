.editorView blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
  
.editorView ol,  .editorView ul {
    padding-left: 1.5em;
}
.editorView .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
}
.editorView li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
}
.editorView .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
}
.editorView li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
}
.editorView .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
}
.editorView li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
}
.editorView .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
}
.editorView li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
}
.editorView .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
}
.editorView li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
}
.editorView .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
}
.editorView li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
}
.editorView .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
}
.editorView li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
}
.editorView .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
}
.editorView li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
}
.editorView .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
}
.editorView li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
}
.editorView .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
}
.editorView li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
}
.editorView .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
}
.editorView li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
}
.editorView .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
}
.editorView li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
}
.editorView .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
}
.editorView li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
}
.editorView .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
}
.editorView li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
}
.editorView .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
}
.editorView li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
}
.editorView .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
}
.editorView li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
}
.editorView .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
}
.editorView li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
}
.editorView .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
}
.editorView li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
}