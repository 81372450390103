@charset "UTF-8";
/* function.css */
/* table style */
.tableBody { display: table; width: 100%; }

/*.TableTransparency { }*/
.TableTransparency th { padding: 0; font-size: 12px; line-height: normal; }
.TableTransparency td { padding: 0; text-align: left; }
.tableView th { background-color: #F8F8F8; border-bottom: 1px solid #ccc; border-right: 1px solid #ccc; font-size: 12px; height: 50px; text-align: right; }
.tableView td { background-color: #fff; border-bottom: 1px solid #ccc; border-right: 1px solid #ccc; height: 50px; text-align: left; }
/* 필수항목 */
.tableList caption { text-align: left; padding: 0 0 10px 10px; }
.tableList > thead > tr > th, .tableList > tbody > tr > th { background-color: #F8F8F8; border-bottom: 1px solid #eaeaea; border-right: 1px solid #eaeaea; font-weight: 700; padding: 15px 16px;}
.tableList > thead > tr > td, .tableList > tbody > tr > td { background-color: #fff; border-bottom: 1px solid #eaeaea; border-right: 1px solid #eaeaea; padding: 8px 16px; line-height: 16px; letter-spacing: -0.5px; height: 48px;}
.tableList > tbody > tr > td { cursor: pointer; }
.tableList > tbody > tr:hover > th, .tableList > tbody > tr:hover > td { background-color: #eaeaea;}
.tableList tr:last-child td { border-bottom: 1px solid #eaeaea;}
.tableList > tbody > tr.selRow td, .tableList > tbody > tr.selRow:nth-child(2n+2) td { background-color: #fffbdf; }
.tableList > tfoot > tr:nth-child(1) > td { background-color: #F8F8F8; }

.tableList > thead > tr > td.noBorder, .tableList > tbody > tr > td.noBorder, td.noBorder { border: none; }
.tableList > tbody > tr > td.bottomBorder { border-bottom: 1px solid #eaeaea; }
.tableList > tbody > tr > td.noData { text-align: center; color: #999; }

.tableList.multi { margin-top: 10px; }
.tableList.multi > tbody > tr:first-child > td { border-bottom: 1px solid #eaeaea; }

.tableSort { cursor: pointer; /*padding-left: 5px;*/ }
.tableSort em { font-style: normal; margin-right: 3px; }
.tableSort img { vertical-align: inherit; }

.tableList.noCursor > tbody > tr > td { cursor: auto; }

th.thL, td.tdL { text-align: left; }
th.thC, td.tdC { text-align: center; }
th.thR, td.tdR { text-align: right; }
th.thVTop, td.tdVTop { vertical-align: top; }
td.none { padding: 200px 0; text-align: center; }
th.rLine, td.rLine { border-right: 1px solid #eaeaea; }

.tableList, .tableView {border-top:1px solid #eaeaea; border-left:1px solid #eaeaea;}
.tableList.full, .tableView.full {border-top:none; border-left:none;}

/* dl dt dd 테이블 형태 */
.boardView {padding-bottom:20px;}
.boardView .boardViewSubj {position:relative;}
.boardView .boardViewSubj p {line-height:24px;}
.boardView .boardViewSubj .snsList {position:absolute; right:20px; top:50%; margin-top:-16px;}
.boardView .boardViewSubj .subj {border-top:2px solid #1d1d21;}
.boardView .boardViewSubj .subj dt {width:114px;}
.boardView .boardViewSubj .info dt:first-child {width:114px;}
.boardView .boardViewSubj .info dt {width:83px;}
.boardView .boardViewSubj dl {overflow:hidden; display:table; width:100%; border-left:1px solid #ccc;}
.boardView .boardViewSubj dt {display:table-cell; height:50px; padding:0 18px; background-color:#fafafa; border-right:1px solid #ccc; border-bottom:1px solid #ccc; vertical-align:middle;}
.boardView .boardViewSubj dd {display:table-cell; background-color:#fff; height:50px; line-height:24px; vertical-align:middle; border-right:1px solid #ccc; border-bottom:1px solid #ccc; padding:0 18px; position:relative;}
.boardView .boardViewConts {border:1px solid #ccc; padding:30px; background-color:#fff; line-height:24px; margin-top:10px;}
/******************************/

/* Paging Style */
.tableFooter {text-align: center;}
.paging { padding: 20px 0; text-align: center; }
.paging button { background: none; border: none; min-width: 28px; height: 29px; padding: 0 7px; text-align: center; cursor: pointer; }
.paging button.img { border: 1px solid #d6d9dd; text-align: center; background-color: #fff; }        
.paging button.img.brNo { border-right: none; }
.paging button.img img, .paging button.img.brNo img { padding-top: 0; vertical-align: middle; }
.paging button:hover { background-color: #eee; color: #000; }
.paging .on { color: #9b704c;}
.paging .no { cursor: not-allowed; }
/******************************/

/* 달력 */
.cal input{position: relative; background-image:url(../../../public/images/icon/calendar.svg); background-repeat: no-repeat; background-size: 24px 24px; background-position: right 16px center; max-width: 145px;}
.cal > div{width: 100%;}

.daterangepicker select.monthselect {float: right; width: 50px;}
.daterangepicker select.yearselect {float: left; width: 50px;} 

/** 자동완성 검색 **/
.ui-autocomplete-loading { background: url(../../../public/images/ajax-loader-s.gif) no-repeat center; }
.ui-autocomplete { max-height: 200px; overflow-y: auto; overflow-x: hidden; }
.ui-menu-item { padding: 3px 6px; }
.ui-menu-item strong { color: #006fc1; }
.ui-menu-item .resultMenu { font-size: 14px; font-weight: 800; }

/* div scroll */
.scrollBox { width: 100%; height: 320px; overflow-x: hiddin; overflow-y: scroll; background: #fff; -ms-overflow-style: none; scrollbar-width: none;}
.scrollBox::-webkit-scrollbar {display: none; /* 크롬, 사파리, 오페라 */}
.scrollBox .tableList { border-collapse: separate; border-spacing: 0; table-layout: fixed; }
.scrollBox .tableList > thead > tr > th { position: sticky; position: -webkit-sticky; top: 0; border-top: none; height: 37px; z-index: 9; }
.scrollBox .tableList > thead > tr > td { position: sticky; position: -webkit-sticky; top: 37px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; height: 37px; }
.scrollBox .tableList > thead > tr:nth-child(2) > th { top: 37px; }
.scrollBox.type1 .tableList > thead > tr > th { border-top: 1px solid #ccc; }
.scrollBox.type2 { border-left: none; border-right: none; }
.scrollBox.type2 .tableList > thead > tr > th { border-top: none; }
.scrollBox.type3 { border-top: 1px solid #ccc; }
.scrollBox.type3 .tableList > thead > tr > th { border-top: none; border-right: 1px solid #ccc; }
.scrollBox.type3 .tableList > thead > tr:nth-child(1) > th:last-child { border-right: none; }
.scrollBox.type4 .tableList > thead > tr > th { border-bottom: none; }

/* 토글버튼 */
.toggleSwitch {width: 50px; height: 24px; display: block; position: relative; border-radius: 30px; background-color: #f2f2f2; border:1px solid #ccc; cursor: pointer; margin: 30px;}
.toggleSwitch .toggleButton {width: 18px; height: 18px; position: absolute; top: 50%; left: 4px; transform: translateY(-50%); border-radius: 50%; background: #3CC7B0; }
.toggleChk:checked ~ .toggleSwitch {background: #3CC7B0;}
.toggleChk:checked ~ .toggleSwitch .toggleButton {left: calc(100% - 22px); background: #fff; }
.toggleSwitch, .toggleButton {transition: all 0.2s ease-in;}

/** Auto Size Layer Popup **/
.dim-layer { display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 999; }
.dim-layer .dimBg { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #000; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 5-7 */ filter: alpha(opacity=50); /* Netscape */ -moz-opacity: 5.0; /* Safari 1.x */ -khtml-opacity: 0.5; /* Good browsers */ opacity: 0.5; }

.autoSizeLayerBg { position: fixed; width: 100%; height: 100%; overflow-x: hidden; overflow-y: auto; z-index: 30; }
.autoSizeLayerT {background-color: #497375;}
.autoSizeLayerTInner { padding: 15px 20px; }
.autoSizeLayer h4, .autoSizeLayer h4 span { width: 100%; cursor: move; color: #fff; font-size: 17px; }
.autoSizeLayer h4 img { vertical-align: middle; }
.autoSizeLayerCls { position: relative; float: right; margin: 15px; } 
.autoSizeLayerCont { padding: 20px; }
.autoSizeLayerCont .popTitle { padding-bottom: 15px; font-size: 11px; }
.autoSizeLayerCont .popTitle img { vertical-align: middle; }
.autoSizeLayerF { text-align: right; padding-top: 15px; }
.autoSizeLayerContBody table {border-right:1px solid #ccc; border-left:1px solid #ccc;}

/** 로딩중 **/
.loading-layer { display: none; position: fixed; top: 50%; left: 50%; width: 100%; height: 100%; z-index: 9999; transform: translate(-50%, -50%);}
.loading-layer .loadingBg { position: fixed; top: 0; left: 0; bottom: 0; width: 100%; height: 100%; background: #fff; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 5-7 */ filter: alpha(opacity=50); /* Netscape */ -moz-opacity: 5.0; /* Safari 1.x */ -khtml-opacity: 0.5; /* Good browsers */ opacity: 0.5; }
.loading-layer .loadingImg { position: absolute; top: 50%; left: 50%; margin: -100px 0 0 -100px; }

/* 레이어버튼 */
.layerBtn{position: absolute; background-color: #fff; border: 1px solid #497375; border-radius: 10px; padding: 10px; box-shadow:1px 1px 10px 3px rgba(0, 0, 0, .2);}
.layerClose{width:12px; display: inline-block; margin-left: 10px;}
.layerClose > img{width:100%; padding: 2px 0;}

/* 탑 버튼 */
#btnPageTop{position: fixed; bottom: 30px; right:110px; width: 60px; height: 60px; border:1px solid #ccc; background-color: #fff; border-radius: 100px; z-index: 100; display: flex; justify-content: center; align-items: center; opacity: .3;}
#btnPageTop img{width:24px; height: 24px; padding: 4px; }
#btnPageTop:hover{animation: bounceIn .5s; opacity: 1;}

/* input label */
label:nth-child(2){margin-left:0;}
input[type="radio"]{display:none;}
input[type="radio"]:checked + label{font-weight:600;}
input[type="radio"] + label em{display:inline-block; width:20px; height:20px; margin-right:10px; border:5px solid #ccc; border-radius:50px; vertical-align:middle;}
input[type="radio"]:checked + label em{border:5px solid #2B9180; }

/* input 공통 */
.phonNum{display: grid; grid-template-columns: 1fr .1fr 1fr .1fr 1fr; gap:5px; width:300px;}
.eMail{display: grid; grid-template-columns: 1fr .1fr 1fr 1fr; gap:10px; width:400px}
.phonNum span, .eMail span{line-height: 31px;}

.betweenBox{display: flex; justify-content: space-between; align-items: center; flex-wrap: nowrap;}
.icInfo{width:14px; height: 14px; vertical-align: text-top; margin-left: 5px;}
.bar{color:#ccc; margin: 0 10px;}

.splitItems { display: grid; gap: 5px; align-items: center; }
.splitItems.email {grid-template-columns: 200px 15px 250px;}
.splitItems.tel {grid-template-columns: 1fr 0.1fr 1fr 0.1fr 1fr;}
.splitItems.link {grid-template-columns: 1fr 0.2fr;}
.splitItems.addr {grid-template-columns: 100px 50px 1fr 1fr;}
.splitItems.date {grid-template-columns: 1fr 0.3fr 1fr;}

/* sns 공유 */
.snsList {float:right;}
.snsList li {float:left; margin-left:5px;}
.snsList li:first-child {margin-left:0;}
.snsList li a {display:block; width:32px; height:32px; border:1px solid #ddd; border-radius:50%; background-color:#fff;}
.snsList .snsIcon {display:inline-block; overflow:hidden; font-size:0; line-height:0; background:url(../../../public/images/icon/ic_sns.png) 0 0 no-repeat; vertical-align:top; text-indent:-9999px; width:32px; height:32px;}
.snsList .snsIcon.email {background-position:0 0;}
.snsList .snsIcon.url {background-position:-36px 0;}
.snsList .snsIcon.facebook {background-position:-72px 0;}
.snsList .snsIcon.blog {background-position:-108px 0;}
.snsList .snsIcon.kakao {background-position:-144px 0;}
.snsList.viewSns {position: relative; margin-top: -20px;}

/*--- input :checkbox ---*/
.formCheckbox {display:inline-block; vertical-align:top; cursor:pointer; position:relative; padding-left:25px; height:25px;}
.formCheckbox + .formCheckbox {margin-left:10px;}
.formCheckbox input[type="checkbox"] {position:absolute; left:0; top:0; opacity:0; width:25px; height:25px; border:0; background-color:transparent}
.formCheckbox .txt {color:#666; font-size:14px; display:block; line-height:25px; padding-left:10px;}
.formCheckbox span:before {content:''; background:url(../../../public/images/icon/ic_check.png) 0 0 no-repeat; width:25px; height:25px; display:inline-block; vertical-align:top; position:absolute; left:0; top:0;}
.formCheckbox input[type="checkbox"]:checked + span:before {background-position:-30px 0;}

/* 에디터 텍스트 무효 */
.editorView *{font: revert ; font-weight: revert !important; text-decoration: revert; color: revert; list-style: revert; list-style-position: inside; text-align: unset; /*overflow: hidden;*/}
/* .editorView ul{padding-left: 1em;} */
.editorView img {width: max-content; vertical-align:baseline;}
.editorView .ql-align-left{text-align: left;}
.editorView .ql-align-center{text-align: center;}
.editorView .ql-align-right{text-align: right;}

/* 데이터 없을 경우 */
.noData{padding: 0 0 40px 0;}

@media all and (max-width:1023px){
    .editorView br{display:block;}
    .tableList>tbody>tr>td>br {display:block;}
}

@media all and (max-width:767px){
    #book .investAreaWrap .investContsTable .ttBox{position: inherit; overflow: hidden;}
    .snsList.viewSns{position: inherit; margin: 10px 0;}
    .snsList{float: none;}
    .cal input{max-width: 100%;}

    #promotion .excelDown > .dateBox > span{margin: 0;}

    .boardView .boardViewSubj .subj{display: grid; grid-template-columns: 1fr; text-align: center;}
    .boardView .boardViewSubj .subj dt{width:100%; display: none;}
    .boardView .boardViewSubj p{font-size: 18px; padding: 20px 0; border-bottom: 1px solid #ccc;}
    .boardView .boardViewSubj dd{display: grid; grid-template-columns: 1fr; position: inherit; padding: 0; height: auto;}
    .boardView .boardViewSubj .snsList{position: inherit; margin-top: 0; overflow: hidden; padding: 10px; margin: 0 auto; }
    
    .tableList > thead > tr > td, .tableList > tbody > tr > td{padding: 8px;}
}