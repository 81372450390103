#noticeView{padding-top: 40px;}
#noticeView > .noticeViewCont > .ncTop{padding-bottom: 24px; border-bottom: 1px solid #eaeaea;}
#noticeView > .noticeViewCont > .ncTop > div{display: flex; gap:8px; align-items: center; margin-bottom: 8px;}
#noticeView > .noticeViewCont > .ncTop > div > .category{font-size: 11px; padding: 4px 6px; border-radius: 4px; font-weight: 700; min-width: 34px;}
#noticeView > .noticeViewCont > .ncTop > div > .categoryNoti{border:1px solid #7056F9; color:#7056F9; background-color: #EAE5FF;}
#noticeView > .noticeViewCont > .ncTop > div > .categoryIssue{border:1px solid #2FB3A4; color:#2FB3A4; background-color: #ECFAF8; min-width: 74px;}
#noticeView > .noticeViewCont > .ncTop > div > .notiTitle{font-size: 18px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
#noticeView > .noticeViewCont > .ncTop > .date{font-size: 12px; color:#999;}
#noticeView > .noticeViewCont > .ncBottom{padding: 24px 0; border-bottom: 1px solid #eaeaea; margin-bottom: 24px;;}
#noticeView > .noticeViewCont > .ncBottom > p{line-height: 150%; color:#666; font-size: 16px;}
#noticeView > .noticeViewCont > a{padding: 0;}