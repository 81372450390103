#login{margin-top: 64px; text-align: center;}
#login > .mobileBox h4{font-size: 20px; font-weight: 700; margin-bottom: 16px;}
#login > .mobileBox input{display: block; margin: 10px auto 0 auto;}
#login > .mobileBox > a{margin: 24px 0; padding: 0;}
#login > .mobileBox > div:nth-child(2){margin-bottom: 8px;}
#login > .mobileBox > .joinBox{display:flex; justify-content: center;}
#login > .mobileBox > .joinBox > li{position: relative;}
#login > .mobileBox > .joinBox > li::after{display: block; content: ''; width:1px; height: 12px; background-color: #D9D9D9; top:50%; transform: translateY(-50%); right: 0; position: absolute;}
#login > .mobileBox > .joinBox > li:last-child::after{display: none;}
#login > .mobileBox > .joinBox > li > a{display: block; padding: 8px 16px; color:#999; letter-spacing: -0.5px;}
#login > .mobileBox > .joinBox > li > a.join{color:#6042F9; font-weight: 700;}

#login .subHeader{display: flex; align-items: center;}
#login .subHeader > a{display: block; padding: 4px; margin-right: 8px;;}
#login .subHeader > a > img{width:24px; }
#login .subHeader > h2{font-size: 18px; font-weight: 700;}

 /* ****************** 반응형 ********************** */
 @media only screen and (min-width : 767px) and(max-width:1023px){
    #login .default{margin-top: 80px;}
}

@media all and (max-width:767px){
    #login{padding: 0; margin-top: 0;}
    #login > .mobileBox{position: absolute; top:50%; left:50%; transform: translate(-50%, -50%); padding: 0 20px;}
    #login > .mobileBox h4{display:none;}
}