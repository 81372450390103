body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 에디터 폰트 설정*/

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=dotum]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=dotum]::before {
  content: '돋움체';
}

/*굴림체 */
@font-face {
  font-family: 'ChosunGu';
  font-weight: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGu.woff') format('woff');
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=goolim]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=goolim]::before {
  content: '굴림체';
  font-family: "ChosunGu";
}

.ql-font-goolim {
  font-family: "ChosunGu";
}
/* 궁서 */
@font-face {
  font-family: 'ChosunGs';
  font-weight: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGs.woff') format('woff');
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=goongsu]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=goongsu]::before {
  content: '궁서';
  font-family: "ChosunGs";
}

.ql-font-goongsu {
  font-family: "ChosunGs";
}

/* 바탕 */
@font-face {
  font-family: 'Batang';
  font-weight: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2205@1.0/SuseongBatang.woff2') format('woff2');
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=batang]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=batang]::before {
  content: '바탕';
  font-family: "Batang";
}

.ql-font-batang {
  font-family: "Batang";
}


/* Arial */
@font-face {
  font-family: 'Arial';
  font-weight: normal;
  src: url('/src/common/font/Arial.ttf') format('woff');
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=arial]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=arial]::before {
  content: 'Arial';
  font-family: "Arial";
}

.ql-font-arial {
  font-family: "Arial";
}

/* Buri */
@import url("https://webfontworld.github.io/amore/AritaBuri.css");

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="buri"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="buri"]::before {
  content: "Buri";
  font-family: "AritaBuri";
}
.ql-font-buri {
  font-family: "AritaBuri";
}

/* Times new roman */
@font-face {
  font-family: 'Times';
  font-weight: normal;
  src: url('/src/common/font/Times new roman.ttf') format('woff');
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=times]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=times]::before {
  content: 'Times';
  font-family: "Times";
}

.ql-font-times {
  font-family: "Times";
}

/* Verdana */
@font-face {
  font-family: 'Verdana';
  font-weight: normal;
  src: url('/src/common/font/Verdana.ttf') format('woff');
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=verdana]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=verdana]::before {
  content: 'Verdana';
  font-family: "Verdana";
}

.ql-font-verdana {
  font-family: "Verdana";
}
/* 에디터 폰트사이즈 */
/* 7px */
.ql-size-7px {
  font-size: 7px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="7px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="7px"]::before {
  content: '7px'!important;
}
/* 8px */
.ql-size-8px {
  font-size: 8px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: '8px'!important;
}
/* 9px */
.ql-size-9px {
  font-size: 9px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: '9px'!important;
}

/* 10px */
.ql-size-10px {
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10px'!important;
}
/* 11px */
.ql-size-11px {
  font-size: 11px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: '11px'!important;
}
/* 12px */
.ql-size-12px {
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: '12px'!important;
}
/* 13px */
.ql-size-13px {
  font-size: 13px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: '13px'!important;
}
/* 14px */
.ql-size-14px {
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14px'!important;
}
/* 15px */
.ql-size-15px {
  font-size: 15px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  content: '15px'!important;
}
/* 16px */
.ql-size-16px {
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px'!important;
}
/* 17px */
.ql-size-17px {
  font-size: 17px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
  content: '17px'!important;
}
/* 18px */
.ql-size-18px {
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px'!important;
}
/* 19px */
.ql-size-19px {
  font-size: 19px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before {
  content: '19px'!important;
}
/* 20px */
.ql-size-20px {
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px'!important;
}
/* 21px */
.ql-size-21px {
  font-size: 21px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before {
  content: '21px'!important;
}
/* 22px */
.ql-size-22px {
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: '22px'!important;
}
/* 23px */
.ql-size-23px {
  font-size: 23px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before {
  content: '23px'!important;
}
/* 24px */
.ql-size-24px {
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: '24px'!important;
}
/* 25px */
.ql-size-25px {
  font-size: 25px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before {
  content: '25px'!important;
}
/* 26px */
.ql-size-26px {
  font-size: 26px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: '26px'!important;
}
/* 27px */
.ql-size-27px {
  font-size: 27px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before {
  content: '27px'!important;
}
/* 28px */
.ql-size-28px {
  font-size: 28px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: '28px'!important;
}
/* 29px */
.ql-size-29px {
  font-size: 29px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="29px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="29px"]::before {
  content: '29px'!important;
}
/* 30px */
.ql-size-30px {
  font-size: 30px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: '30px'!important;
}
/* 31px */
.ql-size-31px {
  font-size: 31px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="31px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="31px"]::before {
  content: '31px'!important;
}
/* 32px */
.ql-size-32px {
  font-size: 32px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: '32px'!important;
}
/* 33px */
.ql-size-33px {
  font-size: 33px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="33px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="33px"]::before {
  content: '33px'!important;
}
/* 34px */
.ql-size-34px {
  font-size: 34px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="34px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="34px"]::before {
  content: '34px'!important;
}
/* 35px */
.ql-size-35px {
  font-size: 35px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="35px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="35px"]::before {
  content: '35px'!important;
}
/* 36px */
.ql-size-36px {
  font-size: 36px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: '36px'!important;
}
