#result{margin-top: 64px;}
/* 아이디 찾기 결과 화면 */
#result > .mobileBox .idResult > h4{font-size: 20px; font-weight: 400; margin-bottom: 16px;}
#result > .mobileBox .idResult > h4 > span{font-size: 20px; font-weight: 700; margin-bottom: 16px;}
#result > .mobileBox .idResult .resultBox{padding: 16px; text-align: center; border:1px solid #eaeaea; border-radius: 8px; margin-bottom: 24px;}
#result > .mobileBox .idResult .resultBox > p:nth-child(1){font-size: 16px; margin-bottom: 8px;}
#result > .mobileBox .idResult .resultBox > p:nth-child(2), .resultBox > p:nth-child(2) > span{font-size: 12px; color:#999;}
/* 비밀번호 찾기 결과 화면 */
#result > .mobileBox .pwResult > h4{font-size: 20px; font-weight: 700; margin-bottom: 16px;}
#result > .mobileBox .pwResult .inputBox > ul > li{margin-bottom: 16px;}
#result > .mobileBox .pwResult .inputBox > ul > li > p{font-size: 12px;}
#result > .mobileBox .pwResult .inputBox .ibsInfo{position: relative;}
#result > .mobileBox .pwResult .inputBox .ibsInfo > input{margin-bottom: 4px;}
#result > .mobileBox .pwResult .inputBox .ibsInfo > span{font-size: 12px; color:#999; margin-bottom: 16px; display: block; padding-left: 16px;}
#result > .mobileBox .pwResult .inputBox .ibsInfo > span.timeLimit{position: absolute; right:14px; top:16px}
#result > .mobileBox .pwResult .inputBox .ibsInfo > span.colRed{color:#FB5555;}
#result > .mobileBox .pwResult .inputBox .ibsInfo > .phonBox .btnPointLine > span{color:#6042F9; font-weight: 700;}
#result > .mobileBox .pwResult .inputBox .ibsInfo > .phonBox{display: grid; gap:10px; grid-template-columns: 2fr 1.2fr; margin-bottom: 4px;}
#result > .mobileBox .pwResult .inputBox .ibsInfo > .phonBox > a{font-size: 14px;}
#result > .mobileBox .pwResult > a {padding: 0;}

@media all and (max-width:767px){
    #result > .mobileBox h4{display:none;}
}