 /* banner */
 #Main .banner{background-color: #F2F6F6; position: relative;}
 #Main .banner ul > li{display: flex; align-items: center; justify-content: space-between;}
 #Main .banner ul > li > img{width:384px;}
 #Main .banner ul > li > .txtBox > .txtTitle{font-size: 44px;}
 #Main .banner ul > li > .txtBox > .txtTitle > span{font-size: 44px;}
 #Main .banner ul > li > .txtBox > .txtSub{margin: 24px 0 32px 0;}
 #Main .banner ul > li > .txtBox > .txtSub.mainLogin{margin-bottom: 0;}
 #Main .banner ul > li > .txtBox > div > a{display: inline-block; padding:16px 24px; font-size: 16px; font-weight: 700; border-radius: 24px;}
 #Main .banner ul > li > .txtBox > div > a:first-child{background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.08); margin-right: 16px;}
 #Main .banner ul > li > .txtBox > div > a:last-child{background-color: #7056F9; color:#fff;}
 /* dot */
 #Main .banner > .dots{display: flex; gap:8px; position: absolute; bottom: 24px; left:50%; transform: translate(-50%);}
 #Main .banner > .dots > .dot > button{cursor: pointer;}
 #Main .banner > .dots > .active > button > span{background-color: #7056F9 !important;}
 #Main .banner > .dots > .dot > button > span{display: inline-block; width:16px; height: 16px; background-color: rgba(0, 0, 0, 0.08); border-radius: 16px; -webkit-transition: 0.3s;}
 /* partnersCon_01 */
 #Main .partnersCon_01{ display: flex; align-items: center; gap:96px; padding: 160px 0 80px 0;}
 #Main .partnersCon_01 > img{width:304px;}
 #Main .partnersCon_01 > .txtBox > .txtSub{width:544px;}
 /* partnersCon_02 */
 #Main .partnersCon_02{padding: 80px 0 160px 0;}
 #Main .partnersCon_02 > .txtBox{text-align: center;}
 #Main .partnersCon_02 > .txtBox > .txtSub{margin: 16px 0 0 0;}
 #Main .partnersCon_02 > ul{margin-top: 80px; display: grid; gap:16px; grid-template-columns: 1fr 1fr 1fr 1fr;}
 #Main .partnersCon_02 > ul > li{text-align: center;}
 #Main .partnersCon_02 > ul > li > div{position: relative; width: 50%; background-color: #F3F7F7; border-radius: 16px; min-width: 224px;}
 #Main .partnersCon_02 > ul > li > div::after{display: block; content: ""; padding-bottom: 100%;}
 #Main .partnersCon_02 > ul > li > div > div{position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; justify-content: center; align-items: center; display: flex;}
 #Main .partnersCon_02 > ul > li > div > div > img{width: 120px; height: 120px;}
 #Main .partnersCon_02 > ul > li > p:nth-child(2){font-size: 18px; margin: 24px 0 8px 0; }
 #Main .partnersCon_02 > ul > li > p:nth-child(3){color:#666;}
 /* partnersCon_03 */
 #Main .partnersCon_03{background: linear-gradient(180deg, #F2F6F6 0%, rgba(242, 246, 246, 0.00) 100%);}
 #Main .partnersCon_03 > div{display: flex; gap:16px; align-items: center; padding: 108px 0 0 0;}
 #Main .partnersCon_03 img{width:544px;}
 /* partnersCon_04 */
 #Main .partnersCon_04{padding: 80px 0;}
 #Main .partnersCon_04 > .txtBox{text-align: center; margin-bottom: 40px;}
 #Main .partnersCon_04 > ul{display: grid; gap:16px; grid-template-columns: 1fr 1fr 1fr; justify-content: center;}
 #Main .partnersCon_04 > ul > li{padding: 24px 16px; border:1px solid #EAEAEA; display: flex; gap:16px; border-radius: 16px; align-items: center; justify-content: center;}
 #Main .partnersCon_04 > ul > li > div{width: 172px;}
 #Main .partnersCon_04 > ul > li > img{width: 84px;}
 #Main .partnersCon_04 > ul > li:last-child > div > img{width:120px;}
 #Main .partnersCon_04 > ul > li > div > p:nth-child(1){margin-bottom: 8px; font-size: 18px;}
 #Main .partnersCon_04 > ul > li > div > p:nth-child(2){color:#666;}


 /* ****************** 반응형 ********************** */
 @media all and (max-width:1023px){
     
     #Main .banner ul > li{justify-content: space-between; }
     #Main .banner ul > li > img{max-width:254px; width:calc(100% - 20px);}
     #Main .banner ul > li > .txtBox > .txtSub{margin: 16px 0 24px 0;}
     #Main .banner ul > li > .txtBox > .txtTitle, .banner ul > li > .txtBox > .txtTitle > span{font-size: 32px !important;}
     #Main .partnersCon_01{gap:40px; padding: 80px 32px 40px 32px;}
     #Main .partnersCon_01 > .txtBox > .txtSub{width:100%;}
     #Main .partnersCon_01 > img{width:254px;}
     #Main .partnersCon_01 > .txtBox > .txtSub{margin: 16px 0 24px 0;}
     #Main .partnersCon_02{padding: 40px 32px 40px 32px;}
     #Main .partnersCon_02 > ul{margin-top: 40px;}
     #Main .partnersCon_02 > ul > li > div{ padding: 42px; min-width: 100%;}
     #Main .partnersCon_02 > ul > li > div > div > img{width: 80px; height: 80px;}
     #Main .partnersCon_02 > ul > li > p:nth-child(2){font-size: 16px; margin: 16px 0 8px 0;}
     #Main > .partnersCon_03{position: relative; height: 385px; overflow: hidden;}
     #Main > .partnersCon_03 > div{width: calc(100% - 64px); display: block; height: 329px; position: absolute; top:56px; margin: 0 32px; padding-top: 0;}
     #Main > .partnersCon_03 .txtBox{position: absolute; top:50%; transform: translateY(-50%); z-index: 9; }
     #Main > .partnersCon_03 img{position: absolute; right: 0; top:50%; transform: translateY(-50%); width: 434px;}
     #Main > .partnersCon_03 .txtBox > .txtSub{word-break: normal !important;}
     #Main > .partnersCon_03 .txtBox > .txtSub br{display: block;}
     #Main .partnersCon_04{padding: 80px 32px;}
     #Main .partnersCon_04 > ul{gap:16px}
     #Main .partnersCon_04 > ul > li{display: block; text-align: center;}
     #Main .partnersCon_04 > ul > li > div{margin: 0 auto;}
     #Main .partnersCon_04 > ul > li > img{margin-bottom: 16px;}
     
 }

 @media all and (max-width:767px){
    #Main .banner{padding: 0;}
     #Main .banner > .bgBox{width:100%;  height: 626px; position: relative;}
     #Main .banner > .bgBox > ul > li{display: block;}
     #Main .banner > .bgBox > ul > li > div{position: absolute; bottom:68px; left:50%; transform: translate(-50%); text-align: center; width:100%;}
     #Main .banner ul > li{flex-direction: column-reverse; gap:40px; }
     #Main .banner ul > li > .txtBox{text-align: center;}
     #Main .banner ul > li > .txtBox > .txtTitle, .banner ul > li > .txtBox > .txtTitle > span{font-size: 24px !important; white-space: nowrap;}
     #Main .banner ul > li > .txtBox > .txtSub{font-size: 14px;}
     #Main .banner > .bgBox > ul > li > img{position: absolute; top:68px; left:50%; transform: translate(-50%);}
     #Main .banner > .dots{position: absolute; bottom: 26px;}

     #Main .partnersCon_01{display: block; text-align: center; padding: 80px 20px 40px 20px;}
     #Main .partnersCon_01 > img{margin-bottom: 24px;}
     
     #Main .partnersCon_02{padding: 40px 20px 80px 20px;}
     #Main .partnersCon_02 > ul{grid-template-columns: 1fr 1fr; padding: 0; margin-top: 20px;}
     #Main .partnersCon_02 > ul > li > div{padding: 0;}

     #Main > .partnersCon_03{overflow: unset; position: unset; height: auto;}
     #Main > .partnersCon_03 > div{padding: 56px 20px 0 20px; margin: 0; margin-bottom: 40px; width: 100%; position: unset; height: auto;}
     #Main > .partnersCon_03 img{width: 100%; position: unset; transform: none; margin-top: 40px;}
     #Main > .partnersCon_03 .txtBox{text-align: center; position: unset; transform: none;}
     #Main > .partnersCon_03 .txtBox > .txtSub{word-break: keep-all !important; margin: 16px 0 24px 0;}
     #Main > .partnersCon_03 .txtBox br{display: none;}

     #Main .partnersCon_04{padding: 80px 0 40px 0;}
     #Main .partnersCon_04 > ul::-webkit-scrollbar {display: none; /* 크롬, 사파리, 오페라 */}
     #Main .partnersCon_04 > ul{overflow-X: scroll; -ms-overflow-style: none; scrollbar-width: none; padding: 0 20px; justify-content: left;}
     #Main .partnersCon_04 > ul > li{min-width: 222px;}
     
 }