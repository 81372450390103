#join{margin-top: 64px; padding-top: 112px}
#join h4{font-size: 20px; font-weight: 700; margin-bottom: 24px; text-align: left;}
#join > a{margin: 24px 0;}
#join .mobileBox .middleTitle{font-size: 16px; margin-bottom: 16px; font-weight: 400;}
#join .mobileBox .inputBox > ul > li{margin-bottom: 16px;}
#join .mobileBox .inputBox > ul > li > p{font-size: 12px;}
#join .mobileBox .inputBox .ibsInfo > input{margin-bottom: 4px;}
#join .mobileBox .inputBox .ibsInfo > span{font-size: 12px; color:#999; margin-bottom: 16px; display: block; padding-left: 16px;}
#join .mobileBox .inputBox .ibsInfo > span.colRed{color:#FB5555;}
#join .mobileBox .inputBox .ibsInfo > .twoInput{display: grid; gap:10px; margin-bottom: 4px; grid-template-columns: 1fr 1fr}
#join a.btn100{padding: 0;}

/* 회원동의 */
#join .consent{border:1px solid #EAEAEA; border-radius: 16px; margin-bottom: 24px;}
#join .consent .agreeAll{padding: 16px; border-bottom: 1px solid #EAEAEA;}
#join .consent .termsBox{justify-content: space-between; align-items: center; display: flex;}
#join .consent .termsBox > .viewTerms {text-decoration: underline;}
#join .consent .agreeAll > .termsBox > span{font-size: 16px; font-weight: 400;}
#join .consent .agree{padding: 16px; }
#join .consent .imageCheckbox {display: flex; align-items: center}
#join .consent .imageCheckbox > p{color:#666;}
#join .consent .imageCheckbox img.checkbox {width: 24px; height: 24px; margin-right: 16px; cursor: pointer;}

/* 회원가입 단계 */
#join .stage{position: absolute; top:-16px; right:0;}
#join .stage > ul{display: flex; gap: 8px;}
#join .stage > ul > li{border-radius: 100px; background-color: #ccc; width:16px; height: 16px; text-align: center;}
#join .stage > ul > li.active{background-color: #6042F9;}
#join .stage > ul > li > span{font-size: 11px; font-weight: 700; color:#fff; display: block; margin-top: 2px;}

#join .layerCont{padding: 24px;}
#join .layerCont select{margin-bottom: 16px;}
#join .layerCont select{margin-bottom: 24px; width:100%;}
#join .layerCont .firstText{margin-bottom: 24px; color:#666; line-height: 22px;}
#join .layerCont .prTitle{margin-bottom: 16px; font-weight: 700; font-size: 16px;}
#join .layerCont .prsubTitle{font-size: 16px;}
#join .layerCont .subText{color:#666; margin: 8px 0 16px 0; line-height: 22px;}
#join .layerCont .subText > span{color:#666; padding-left: 16px; display: block; margin-top: 8px;}

@media all and (max-width:767px){
#join{padding-top: 72px;}
/* 모바일 팝업 */
#join .consentForm .layerCon{width: 100%; height: 100%; left:0; top:0; transform: none; min-width: 100%; max-width: none; border-radius: 0;}
#join .consentForm > .layerCon .layerTop > .layerCont{height: calc(100vh - 61px)}
#join .consentForm > .layerCon .layerTop > .layerCont .scrollBox{height:100%;}
#join .consentForm > .layerCon .popBtn{display: none;}


}