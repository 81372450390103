#promotion{padding-top: 44px;}
/* 상단 컨텐츠 */
#promotion .prmoTop{display: flex; gap:48px; align-items: center; width:100%; margin-bottom: 40px;}
#promotion .prmoTop > .imgBox{width:224px; height: 224px; border-radius: 16px; background-color: #6042F9; display: flex; justify-content: center; align-items: center;}
#promotion .prmoTop > .imgBox > img{width: 74px;}
#promotion .prmoTop > .urlBox{flex-grow: 1;}
#promotion .prmoTop > .urlBox > li{margin-bottom: 16px;}
#promotion .prmoTop > .urlBox > li:last-child{margin-bottom: 0;}
#promotion .prmoTop > .urlBox > li > p{margin-bottom: 4px;}
#promotion .prmoTop > .urlBox > li > div{display: grid; grid-template-columns: auto 106px; gap:16px; }
/* 기본 정보 */
#promotion .promoBottom > .baseInfo > div{margin-bottom: 24px;}
#promotion .promoBottom > .baseInfo > div > .tpBox{margin-bottom: 40px;}

/* 이미지 */
#promotion .promoBottom > .img > ul{display: grid; gap:40px 16px; grid-template-columns: 1fr 1fr 1fr 1fr; margin-bottom: 32px;}
#promotion .promoBottom > .img > ul > li > .imgBox{position: relative; overflow: hidden;}
#promotion .promoBottom > .img > ul > li > .imgBox::after{content: ''; display: block; padding-bottom: 100%;}
#promotion .promoBottom > .img > ul > li > .imgBox > a{position: absolute; display: block; width:40px; padding: 8px; top:8px; right:8px; background-color: rgba(0, 0, 0, 0.48); border-radius: 40px;  justify-content: center; align-items: center;}
#promotion .promoBottom > .img > ul > li > .imgBox > img{position: absolute; object-fit: cover; width: inherit; object-position: 50% 50%; width: 100%; height: 100%;}
#promotion .promoBottom > .img > ul > li > .txtBox{margin-top: 8px; overflow: hidden; text-overflow: ellipsis; display:-webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
#promotion .promoBottom > .img > ul > li > .txtBox > p{font-size: 16px;}
/* 영상 */
#promotion .promoBottom > .video > ul{display: grid; gap:40px 16px; grid-template-columns: 1fr 1fr; margin-bottom: 40px;}
#promotion .promoBottom > .video > ul > li > .imgBox{position: relative;}
#promotion .promoBottom > .video > ul > li > .imgBox > a{position: absolute; display: block; width:40px; padding: 8px; top:8px; right:8px; background-color: rgba(0, 0, 0, 0.48); border-radius: 40px;}
#promotion .promoBottom > .video > ul > li > .txtBox{margin-top: 8px; overflow: hidden; text-overflow: ellipsis; display:-webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
#promotion .promoBottom > .video > ul > li > .txtBox > p{font-size: 16px; -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; }

/* 반응형 */
@media all and (max-width:767px){
    #promotion{padding: 0;}
    #promotion .tabBox > ul{justify-content: left; padding: 0 20px;}
    #promotion > .subTitle{padding: 40px 20px 0 20px;}
    #promotion > .prmoTop, .baseInfo, .img, .video{padding: 0 20px;}
    #promotion .pagingDiv{padding-bottom: 40px;}
    

    #promotion .prmoTop{display: block;}
    #promotion .prmoTop > .imgBox{width: 100%; margin-bottom: 24px;}
    #promotion .promoBottom > .img > ul > li > .imgBox{width:100%; margin-bottom: 24px;}
    #promotion .promoBottom > .img > ul{grid-template-columns: 1fr 1fr; gap: 16px;}
    #promotion .promoBottom > .video > ul{grid-template-columns: 1fr;}
}