#guide{padding-top: 40px;}
#guide .guideCont{margin: 160px 0;}
#guide .guideCont{margin: 160px 0;}
#guide .guideCont:last-child{margin-bottom: 0;}
/* .guide_01 */
#guide > .guide_01{display: flex; gap:96px; align-items: center; margin-top: 48px;}
#guide > .guide_01 > img{width:304px;}
/* guide_02 */
#guide > .guide_02 > .txtBox{text-align: center;}
#guide > .guide_02 > .txtBox > .txtSub{margin: 16px 0 0 0;}
#guide > .guide_02 > ul{margin-top: 40px; display: flex; gap:16px;}
#guide > .guide_02 > ul > li{text-align: center;}
#guide > .guide_02 > ul > li > div{display: flex; justify-content: center; align-items: center; background-color: #F3F7F7; width:224px; height: 224px; border-radius: 16px;}
#guide > .guide_02 > ul > li > div > img{width:120px;}
#guide > .guide_02 > ul > li > p:nth-child(2){font-size: 18px; margin: 24px 0 8px 0;}
#guide > .guide_02 > ul > li > p:nth-child(3){color:#666;}
/* guide_03 */
#guide > .guide_03 > .txtBox{text-align: center;}
#guide > .guide_03 > .txtBox > .txtSub{margin: 16px 0 0 0;}
#guide > .guide_03 > ul{margin-top: 40px; display: flex; gap:16px;}
#guide > .guide_03 > ul > li{text-align: center;}
#guide > .guide_03 > ul > li > div{display: flex; justify-content: center; align-items: center; width:304px; height: 326px; border-radius: 16px; padding: 16px; border: 1px solid rgba(0, 0, 0, 0.08); background: #FFF;}
#guide > .guide_03 > ul > li > div > img{width:272px;}
#guide > .guide_03 > ul > li > p:nth-child(2){font-size: 18px; margin: 24px 0 8px 0;}
#guide > .guide_03 > ul > li > p:nth-child(3){color:#666;}

 /* ****************** 반응형 ********************** */
 @media all and (max-width:1023px){
    #guide{padding-bottom: 80px;}
    #guide .guideCont{margin: 0; }
    #guide .guideCont:last-child{padding-bottom: 0;}

    #guide > .guide_01{gap:40px; padding-top: 48px;}
    #guide > .guide_01 > img{width:254px;}
    #guide > .guide_01 .txtBox > .txtSub{margin:16px 0 0 0;}
    #guide > .guide_02 {padding: 80px 0;}
    #guide > .guide_02 > ul{justify-content: center;}
    #guide > .guide_02 > ul > li{width: 164px;}
    #guide > .guide_02 > ul > li > div{height: 152px; width:100%;}
    #guide > .guide_02 > ul > li > div > img{width: 80px;}
    #guide > .guide_02 > ul > li > p:nth-child(2){font-size: 16px; margin: 16px 0 8px 0;}
    #guide > .guide_03 > ul > li{margin-bottom: 24px;}
    #guide > .guide_03 > ul > li:last-child{margin-bottom: 0;}
    #guide > .guide_03 > ul > li > div{ width: 100%;}
    #guide > .guide_03 > ul > li > div > img{width: 100%;}
    #guide > .guide_03 > ul > li > p:nth-child(2){font-size: 16px; margin: 16px 0 8px 0;}
    
}

@media all and (max-width:767px){
    #guide{padding-bottom: 40px;}
    #guide > .guide_01{display: block; text-align: center;}
    #guide > .guide_01 > img{margin-bottom: 24px;}
    #guide > .guide_01 .txtBox > .txtSub{margin:16px 0 0 0 ;}
    #guide > .guide_01 .txtBox > .txtSub br{display: none;}
    #guide > .guide_02 > ul{display: grid; grid-template-columns: 1fr 1fr; margin-top: 24px;}
    #guide > .guide_02 > ul > li{width: auto;}
    #guide > .guide_02 > .txtBox > .txtSub{width: 200px; margin: 16px auto 0 auto;;}
    #guide > .guide_03 > ul{display: block;}
    #guide > .guide_03 > ul > li > div{margin: 0 auto; padding: 16px; max-width: 224px; height: 224px;}
   
}