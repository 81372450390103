@charset "UTF-8";

/* 탭메뉴 */
.tabBox{border-bottom: 1px solid #eaeaea; margin-bottom: 24px;}
.tabBox > ul{display: flex;}
.tabBox > ul > li.active{border-bottom: 2px solid #6042F9;}
.tabBox > ul > li.active > a{color:#6042F9; font-weight: 700;}
.tabBox > ul > li > a{font-size: 16px; color:#999; display: block; padding: 8px 16px; letter-spacing: -0.5px;}

@media all and (max-width:767px){
    

}