#privte{padding-top: 44px;}
#privte h4{font-size: 20px; font-weight: 700;}
#privte select{margin: 24px 0; width:320px;}
#privte .firstText{margin-bottom: 24px; color:#666; line-height: 22px;}
#privte .prTitle{margin-bottom: 16px; font-weight: 700; font-size: 16px;}
#privte .prsubTitle{font-size: 16px;}
#privte .subText{color:#666; margin: 8px 0 16px 0; line-height: 22px;}
#privte .subText > span{color:#666; padding-left: 16px; display: block; margin-top: 8px;}

/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
}

@media all and (max-width:767px){
    #privte{padding-top: 24px;}
    #privte select{margin:0 0 24px 0; width:100%;}
}