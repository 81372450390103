@charset "UTF-8";

body { -webkit-text-size-adjust: 100%; }

.subHeader{display: flex; align-items: center;}
.subHeader > a{display: block; padding: 4px; margin: 0 8px 0 -8px;}
.subHeader > a > img{width:24px; }
.subHeader > h2{font-size: 18px; font-weight: 700;}

/* header */
.headerWrap{position: fixed; top:0; left:0; width:100%; border-bottom: 1px solid #eaeaea; background-color: #fff; height: 64px; z-index: 9999;}
.header{width:944px; padding: 14px 8px; display: grid; grid-template-columns: 105px auto; gap:48px; margin: 0 auto;}
.header > h1.logo{width:86px;}
.header > h1.logo > a{display: block; width: 100%; height: 100%;}
.header header{display: flex; justify-content: space-between;}
.oper {background-color: rgba(255, 255, 255, 0.8)}
.webMenu{display: flex; gap:16px;}
.webMenu > li{position: relative;}
.webMenu > li > a{display: block; padding: 8px 16px; color:#222; font-size: 16px;}
.webMenu > li > .miniDepth{width:160px; display: none; position: absolute; box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); background-color: #fff; padding: 16px 0; border-radius: 16px; border:1px solid #ebebeb; z-index: 99999;}
.webMenu > li:hover .miniDepth{display: block;}
.webMenu > li > .miniDepth > li > a{display: block; padding: 8px 16px;}
.webMenu > li .miniDepth a:hover{font-weight: 700;}
.webMenu > li > a.active{color:#6042F9; font-weight: 600;}

.headRight{display: flex; gap:20px;}
.headRight > div > a{display: block; width:32px; height: 32px; border-radius: 32px;}
.headRight > div > a > span{display: none;}
/* 프로필 */
.headRight > div > a.progileLogin{background-color: #7056F9; display: flex; justify-content: center; align-items: center;}
.headRight > div > a.progileLogin > img{display: none;}
.headRight > div > a.progileLogin > span{font-size: 16px; font-weight: 700; color:#fff; display: block;}
.headRight > .mobileMenu > a{padding: 4px;}
.headRight > .mobileMenu > .gnb{display: none ;}
.headRight > .profileBox{position: relative;}
.headRight > .profileBox > ul{display: none; width:160px; border:1px solid #ebebeb; box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); position: absolute; right:0; top:40px; padding: 16px 0; border-radius: 16px; background-color: #fff;}
.headRight > .profileBox > ul > li > a{display: block; padding: 8px 16px;}

/* 한줄공지 */
.noticeCon{padding: 15px 32px; border-bottom: 1px solid #eaeaea;}
.noticeCon > div{display: grid; grid-template-columns: 36px auto; gap:8px; width:944px; margin: 0 auto; align-items: center;}
.noticeCon > div > p > span{color:#666;}
.noticeCon .state{color:#7056F9; font-size: 11px; font-weight: 700; padding: 4px 6px; border:1px solid #7056F9; background-color: #eae5ff; border-radius: 4px;}

/* footer */
footer{border-top: 1px solid #eaeaea; position: relative; bottom: 0; left:0; width:100%; background-color: #fff; width:100%; }
.footer{width:944px; margin: 0 auto; display: flex; padding: 48px 0; justify-content: space-between; align-items: flex-end;}
.footer > .leftFooter h3{width:86px; margin-bottom: 24px;}
.footer > .leftFooter > p, .footer > .leftFooter > p > a{color:#666; font-size: 12px; line-height: 22px;}
.leftFooter > p > a{text-decoration-line: underline;}
.footer > .leftFooter > p:nth-child(3){font-weight: 700; font-size: 18px; color:#222; margin-top: 24px;}
.footer > .rightFooter{text-align: center;}
.footer > .rightFooter > p:nth-child(2){color:#666; margin-top: 16px;}
.footer > .rightFooter > p:nth-child(2) > b{color:#666;}
.footer > .rightFooter > .copyright, .footer > .rightFooter > .copyright > b{font-size: 12px; color:#666;}
.appStore{display: grid; grid-template-columns: 156px 156px; gap:8px; margin: 16px 0 24px 0;}
.appStore > a{display: block; padding: 10px; text-align: center; border:1px solid rgba(0, 0, 0, 0.08); width:156px; border-radius: 28px;}
.appStore > a > img{width:20px; height: 20px; margin-right: 12px;}

/* 띄어쓰기 */
.empty{margin: 0 12px; width:1px; height: 12px; background-color: #d9d9d9; display: inline-block;}

/* index 공통 */
.partners{margin-top: 64px;}
.partnersCon{width:944px; margin: 0 auto; padding: 160px 0;}
.bgBox{width:944px; margin: 0 auto; padding: 68px 0;}
/* padding: 160px 0 0 0; */

.txtBox > .txtTitle{font-size: 32px;}
.txtBox > .txtTitle > span{font-size: 32px; font-weight: 700;}
.txtBox > .txtSub{margin: 24px 0; color:#666; font-size: 16px; line-height: 150%;}
.txtBox > .btnLink{display: inline-block; padding: 16px 24px; text-align: center; border:1px solid rgba(0, 0, 0, 0.08);border-radius: 24px; font-weight: 700; background-color: #fff;}

/* 서브페이지 공통 */
.subCont{min-height: calc(100vh - 470px); width:944px; margin: 0 auto; padding:80px 0;}
.subTitle{margin-bottom: 24px;}
.subTitle > h4{font-size: 20px; font-weight: 700; margin-bottom: 16px;}
.subTitle > p{color:#999;}
.subTitle ul{list-style: inside; margin-top: 8px;}
.subTitle ul > li{color:#999;}
.centerBox{width:944px; margin: 0 auto;}
.mobileBox{width:320px; margin: 0 auto; position: relative;}
.pageInfo{margin-top: 64px; background-color: #f4f4f4; padding:10px 0; margin: 64px auto 0 auto;}
.pageInfo > div > span{color:#999; cursor: pointer;}
.pageInfo > div > span::after{display: inline-block; content: '|'; margin: 0 16px;}
.pageInfo > div > span:last-child{color:#222;}
.pageInfo > div > span:last-child:after{display: none;}

/* 페이징 */
.pagingDiv {padding: 10px; display: table; margin-left: auto; margin-right: auto; text-align: center; margin-top: 32px;}
.pagingDiv ul{display: flex; justify-content: center; gap:6px;}
.pagination li{display: block;}
.pagination li button {width:40px; height: 40px; color:#999; background-color: #fff; border: 1px solid #eee; border-radius: 4px; align-items: center; font-size: 12px; line-height: 14px; letter-spacing: 0.25px;}
.pagination li button img {height: 16px; width: 16px; }
.pagination .disabled {cursor: not-allowed; background-color: #fff;}
.pagination button:hover {background: #eee;}
.pagination li>.active, .pagination li>.active:focus, .pagination li>.active:hover {color: #fff; cursor: default; background-color: #6042F9; border-color: #6042F9; border-radius: 2px; font-weight: 600;}
button {cursor: pointer;}
.first > button, .prev > button, .next > button, .last > button{padding: 0 !important;}

/* 레이어팝업 */
.layerPop{position: fixed; top:0; left:0; width:100%; height: 100vh; background: rgba(0, 0, 0, 0.32);backdrop-filter: blur(4px); z-index: 999999;}
.layerPop .layerCon{position: absolute; top:50%; left:50%; transform: translate(-50%, -50%);  border-radius: 16px; overflow: hidden; width: 280px}
.layerPop > .layerCon .layerTop{border:1px solid #eaeaea;}
.layerPop > .layerCon .layerTop > .ltCont{padding: 18px 24px; background-color: #fff; border-bottom: 1px solid #eaeaea; display: flex; align-items: center; justify-content: space-between;}
.layerPop > .layerCon .layerTop > .ltCont > p{font-size: 18px; font-weight: 700;}
.layerPop > .layerCon .layerTop > .ltCont > a{display: block; width:24px; height: 24px;}
.layerPop > .layerCon .layerTop > .layerCont{background-color: #fff; padding: 24px;}
.layerPop > .layerCon .layerCont > p{color:#666; text-align: left; word-break: keep-all;}
.layerPop > .layerCon .popBtn{width:100%; background-color:#6042F9; color:#fff; padding: 14px 0 15px 0; text-align: center; display: block; font-weight: 700;}
.layerPop > .layerCon .twoBtn{display: flex;}
.layerPop > .layerCon .twoBtn > .popbtn{width:50%;}
.layerPop > .layerCon .twoBtn > a:first-child{background-color: #f4f4f4; color:#bbb;}
/* ****************** LAYOUT ********************** */
@media all and (max-width:1023px){
	html{min-width: 280px;}
	.header{width:100%; padding: 14px 32px;}
	.subCont{width:100%; padding: 40px 32px;}
	.pageInfo{padding: 10px 32px; width: 100%;}
	/* index 공통 */
	.partnersCon{width:100%; padding: 80px 32px;}
	.bgBox{ padding: 68px 32px; margin:  0 auto; width: 100%;}
	.txtBox > .txtTitle{font-size: 24px;}
	.txtBox > .txtTitle > span{font-size: 24px;}
	.txtBox > .txtSub{word-break: keep-all;}
	.txtBox > .txtSub br{display: none;}
	.txtBox .txtTitle br{display: block;}

	/* 서브페이지 공통 */
	.centerBox{width:100%;}
	.footer{width:100%; padding: 48px 32px; width: 100%;}
	.footer br{display: block;}
	.noticeCon > div{width: 100%;}
}

@media all and (max-width:767px){
	.header{display: flex; justify-content: space-between; width:100%; padding: 16px 20px;}
	.header header{display: block;}
	.header header .webMenu{display: none;}
	.header header > .headRight > .mobileMenu{display: block !important;}
	.header header > .headRight > .mobileMenu > .gnb{width:100vw; height: 100vh; background: #00000052; position: absolute; top:0; left: 0; z-index: 999;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg{display: block; width:320px; background-color: #fff; height: 100vh; position: absolute; top:0; left:0; z-index: 999 !important;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > div{width:100%; display: flex; justify-content: space-between; align-items: center; padding: 20px; border-bottom: 1px solid #eaeaea; z-index: 9999;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > div > a{display: block;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > div > a:nth-child(1){font-size: 16px; line-height: 24px;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > div > a:nth-child(1) > img{width:24px; margin-right: 8px;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > div > a:nth-child(2) > img{width:24px}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > ul.nav{padding: 40px 20px;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > ul.nav >li.depthOne{margin-bottom: 24px;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > ul.nav > li.depthOne > a{display: block; padding: 8px 16px; font-size: 20px;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > ul.nav > li.depthOne > ul.depthTwo > li{margin-bottom: 4px;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > ul.nav > li.depthOne > ul.depthTwo > li:last-child{margin-bottom: 0;}
	.header header > .headRight > .mobileMenu > .gnb .gnbBg > ul.nav > li.depthOne > ul.depthTwo > li > a{display: block; padding: 8px 16px; color:#666;}

	.bgBox{ padding: 78px 20px; width: 100%;}
	/* .bgBox{ padding: 115px 20px;} 로그인 후 패딩값 변경*/

	.txtBox > .txtTitle{font-size: 20px;}
	.txtBox > .txtTitle br{display: block;}
	.txtBox > .txtTitle > span{font-size: 20px;}
	.txtBox > .txtSub{font-size: 14px;}
	.guide > .guide_03 > .txtBox > .txtSub{font-size: 14px;}

	.noticeCon{padding: 14px 20px;}
	.noticeCon > div{width: 100%;}
	.noticeCon > div > a{width:100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
	.partnersCon{padding: 80px 20px 0 20px; width:100%;}
	.partnersCon:last-child{padding: 40px 20px 80px 20px;}

	.subCont{padding: 40px 20px; margin-top: 64px;}
	.pageInfo{display: none;}
	.pagingDiv{padding: 0;}
	.mobileBox{width: 100%;}
	.tableBox{overflow-y: scroll; -ms-overflow-style: none; scrollbar-width: none;}
    .tableBox::-webkit-scrollbar {display: none; /* 크롬, 사파리, 오페라 */}
    .tableBox > table{min-width: 944px;}
	.tableBox thead > tr > th{padding: 15px 8px;}
	.tableBox tbody > tr > td:first-child, .tableBox thead > tr:first-child > th:first-child{position: sticky; left: 0; border-right: 1px solid #eaeaea; }
	
	/* footer */
	.footer{display: block; padding: 0;}
	.footer > div{padding: 40px 20px;}
	.footer .rightFooter .appStore{margin: 16px auto 20px auto; justify-content: center;  grid-template-columns: 1fr 1fr;}
	.footer .rightFooter .appStore > a{background-color: #fff; width: 100%; max-width: 156px;}
	.footer .rightFooter .appStore > a:first-child{justify-self: right;}
	.footer .rightFooter{background-color: #f8f8f8; padding: 24px 20px 48px 20px;}	
}