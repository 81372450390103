#ask{padding-top: 40px;}
#ask > .totalBox{margin-bottom: 24px; display: flex; justify-content: space-between; align-items: center;}
#ask > .totalBox > p{color:#999;}
#ask > .askCont > ul{display: grid; grid-template-columns: 1fr 1fr; gap:16px; cursor: pointer;}
#ask > .askCont > ul > li{border:1px solid #eaeaea; border-radius: 16px; padding: 16px; background-color: #fff; transition: all .3s; min-width: 344px;}
#ask > .askCont > ul > li:hover{box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); transition: all .3s;}
#ask > .askCont > ul > li > .notiInfo{display: flex; justify-content: space-between; align-items: center;}
#ask > .askCont > ul > li > .notiInfo > .category{font-size: 11px; padding: 4px 6px; border-radius: 4px; font-weight: 700;}
#ask > .askCont > ul > li > .notiInfo > .categoryCompletion{border:1px solid #7056F9; color:#7056F9; background-color: #EAE5FF;}
#ask > .askCont > ul > li > .notiInfo > .categoryWaiting{border:1px solid #eaeaea; color:#999; background-color: #f8f8f8;}
#ask > .askCont > ul > li > .notiInfo > .no{font-size: 12px; color:#999;}
#ask > .askCont > ul > li > .notiTitle{margin: 10px 0 4px 0; overflow: hidden; text-overflow: ellipsis; display:-webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
#ask > .askCont > ul > li > .con > p{color:#666; line-height: 150%;}
#ask > .askCont > ul > li > .date{font-size: 12px; color:#999;}

#ask > .askCont > .noData{grid-template-columns: 1fr;}
#ask > .askCont > .noData > li{border: 0; text-align: center; color:#999}

/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
    #ask > .askCont > ul > li > .notiTitle > p{word-break: normal;}
}

@media all and (max-width:767px){
    #ask > .askCont > ul{grid-template-columns: 1fr;}
    #ask > .askCont > ul > li{min-width: 100%;}
    
}