#promotionView{padding-top: 40px;}
#promotionView .excelDown{display: flex; justify-content: space-between; margin-bottom: 24px;}
#promotionView .excelDown > .dateBox{display: flex; align-items: center;}
#promotionView .excelDown > .dateBox > input{width:145px;}
#promotionView .excelDown > .dateBox > span{margin: 0 16px;}
#promotionView .excelDown > .dateBox > a{width:106px; margin-left: 16px;}
#promotionView .excelDown select{width: 320px;}
#promotionView .exelBtn{min-width: fit-content ;}
#promotionView .addBox{border:1px solid #dadada; box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; display: flex; justify-content: center; align-items: center; gap:8px; margin: 24px 0 32px 0;}
#promotionView .addBox > li{text-align: center; padding: 24px 16px; min-width: 192px;}
#promotionView .addBox > li > p:nth-child(1){color:#999; margin-bottom: 8px;}
#promotionView .addBox > li > p:nth-child(2){font-size: 18px; font-weight: 700;}
/* ****************** 반응형 ********************** */
@media all and (max-width:767px){
    #promotionView{padding: 0;}
    #promotionView .tabBox > ul{justify-content: left; padding: 0 20px;}
    #promotionView > .subTitle{padding: 40px 20px 0 20px;}
    #promotionView > div:nth-child(3){padding: 0 20px;}
    #promotionView .pagingDiv{padding-bottom: 40px;}

    #promotionView .excelDown{display: block;}
    #promotionView .excelDown > .dateBox{display: grid; grid-template-columns: 1fr 5px 1fr; gap:0 8px;}
    #promotionView .excelDown > .dateBox > input{width: 100%;}
    #promotionView .excelDown > .dateBox > a{width: 100%; margin: 16px 0; grid-column: 1/4; grid-row: 2/3;}
    #promotionView .excelDown > a{width: 100%;}
    #promotionView .excelDown select{width: 100%; margin-bottom: 16px;}
    #promotionView .addBox{display: block; padding: 8px 0;}
    #promotionView .addBox > li{display: flex; justify-content: space-between; padding: 16px;}
    #promotionView .monthPromotion > .excelDown select{width: 100%; margin-bottom: 16px;}
    #tableBox tbody > tr > td:first-child, .tableBox tbody > tr > th:first-child{position: static;}
}