#askView{padding-top: 40px;}
#askView > .askViewCont > .askTop{padding-bottom: 24px; border-bottom: 1px solid #eaeaea;}
#askView > .askViewCont > .askTop > div{margin-bottom: 8px;}
#askView > .askViewCont > .askTop > div > .category{font-size: 11px; padding: 4px 6px; border-radius: 4px; font-weight: 700;}
#askView > .askViewCont > .askTop > div > .categoryNoti{border:1px solid #7056F9; color:#7056F9; background-color: #EAE5FF;}
#askView > .askViewCont > .askTop > div > .categoryIssue{border:1px solid #2FB3A4; color:#2FB3A4; background-color: #ECFAF8;}
#askView > .askViewCont > .askTop > div > .askTitle{font-size: 18px;}
#askView > .askViewCont > .askTop > .date{font-size: 12px; color:#999;}
#askView > .askViewCont > .askBottom{border-bottom: 1px solid #eaeaea; margin-bottom: 24px;;}
#askView > .askViewCont > .askBottom > p{padding: 24px 0;}
#askView > .askViewCont > .askBottom > p > .askBottomSpan {font-size: 12px; color:#999; margin-bottom: 8px;}
#askView > .askViewCont > .askBottom > .answer{background-color: #F9FCFC; padding: 24px 16px; border-top: 1px solid #eaeaea;}
#askView > .askViewCont > .askBottom > .answer > .answerInfo{display: flex; gap: 8px; align-items: center; margin-bottom: 8px;}
#askView > .askViewCont > .askBottom > .answer > .answerInfo > span{display: block; font-size: 12px; font-weight: 700; color:#7056F9}
#askView > .askViewCont > .askBottom > .answer > .answerInfo > p{font-size: 12px; color: #999;}
#askView > .askViewCont > .askBottom > .answer > p{line-height: 150%; color:#666; font-size: 16px;}
#askView > .askViewCont > .askBtnBox{display: flex; justify-content: space-between; align-items: center;}
#askView > .askViewCont > .askBtnBox > div{display: flex;}
#askView > .askViewCont > .askBtnBox a{display: block; height: 48px; line-height: 48px;}
#askView > .askViewCont > .askBtnBox > div > a:first-child{margin-right: 16px;}