#Performance{padding-top: 40px;}
#Performance .excelDown{display: flex; justify-content: space-between; margin-bottom: 24px;}
#Performance .excelDown > .dateBox{display: flex; align-items: center;}
#Performance .excelDown > .dateBox > input{width:145px;}
#Performance .excelDown > .dateBox > a{width:106px; margin-left: 16px;}
#Performance .excelDown > .dateBox > span{margin:0 16px;}
#Performance .monthPerformance > .excelDown select{width: 320px;}

#Performance .addBox{border:1px solid #dadada; box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; padding: 24px 16px; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; margin: 24px 0 32px 0;}
#Performance .addBox > li{text-align: center;}
#Performance .addBox > li > p:nth-child(1){color:#999; margin-bottom: 8px;}
#Performance .addBox > li > p:nth-child(2){font-size: 18px; font-weight: 700;}
/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
    #Performance .addBox{justify-content: center; grid-template-columns: 108px 108px 108px 180px 168px;}
    #Performance .exelBtn{min-width: fit-content ;}
}
@media all and (max-width:767px){
    #Performance{padding: 0;}
    #Performance .tabBox > ul{justify-content: left; padding: 0 20px;}
    #Performance > .subTitle{padding: 40px 20px 0 20px;}
    #Performance > div:nth-child(3){padding: 0 20px;}
    #Performance .pagingDiv{padding-bottom: 40px;}

    #Performance .excelDown{display: block;}
    #Performance .excelDown > .dateBox{display: grid; grid-template-columns: 1fr 5px 1fr; gap:0 8px;}
    #Performance .excelDown > .dateBox > input{width: 100%;}
    #Performance .excelDown > .dateBox > a{width: 100%; margin: 16px 0; grid-column: 1/4; grid-row: 2/3;}
    #Performance .excelDown > a{width: 100%; }
    #Performance .addBox{display: block; padding: 0; padding: 8px 0;}
    #Performance .addBox > li{display: flex; justify-content: space-between; padding: 16px; }
    #Performance .monthPerformance > .excelDown select{width: 100%; margin-bottom: 16px;}
}