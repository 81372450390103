#agreement{padding-top: 44px;}

#agreement select{margin: 24px 0; width:320px;}
#agreement .firstText{margin-bottom: 24px; color:#666; line-height: 22px;}
#agreement .prTitle{margin-bottom: 16px; font-weight: 700; font-size: 16px;}
#agreement .prsubTitle{font-size: 16px;}
#agreement .subText{color:#666; margin: 8px 0 16px 0; line-height: 22px;}
#agreement .subText > span{color:#666; padding-left: 16px; display: block; margin-top: 8px;}
       
/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
}

@media all and (max-width:767px){
    #agreement{padding: 24px 20px !important}
    #agreement select{margin: 24px 0; width:100%;}
}