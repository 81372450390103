#introduction{padding-top: 40px; width: 100%;}
#introduction .introductionCont{margin: 40px 0;}
#introduction .introductionCont:last-child{margin-bottom: 0;}
#introduction .subTitle{width: 944px; margin: 0 auto;}
/* .introduction_01 */
#introduction > .introduction_01{width: 944px; margin: 0 auto; padding: 80px 0 0 0;}
#introduction > .introduction_01{display: flex; gap:16px; align-items: center;}
#introduction > .introduction_01 > img{width:544px;}
/* introduction_02 */
#introduction > .introduction_02{width: 944px; margin: 0 auto; padding: 80px 0;}
#introduction > .introduction_02 > .txtBox{margin-bottom: 40px;}
#introduction > .introduction_02 > ul{display: grid; gap:16px; grid-template-columns: 1fr 1fr 1fr;}
#introduction > .introduction_02 > ul > li{display: flex; gap: 16px; padding: 24px 16px; border: 1px solid #EAEAEA; border-radius: 16px; align-items: center;}
#introduction > .introduction_02 > ul > li > img{width:84px; height: 64px;}
#introduction > .introduction_02 > ul > li > div > p:nth-child(1){margin-bottom: 8px; color: #222; font-size: 18px;}
#introduction > .introduction_02 > ul > li > div > p:nth-child(2){color:#666;}
/* introduction_03 */
#introduction > .introduction_03{width: 944px; margin: 0 auto; padding: 80px 0;}
#introduction > .introduction_03 > .txtBox{margin-bottom: 40px;}
#introduction > .introduction_03 > ul{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap:16px; }
#introduction > .introduction_03 > ul > li{text-align: center; padding: 24px 16px; background-color: #F3F7F7; border-radius: 16px; border: 1px solid rgba(0, 0, 0, 0.04);}
#introduction > .introduction_03 > ul > li div{width: 80px; height: 80px; background-color: #fff; border-radius: 80px; display: flex; justify-content: center; align-items: center; margin: 0 auto;}
#introduction > .introduction_03 > ul > li div > img{width: 50%;;}
#introduction > .introduction_03 > ul > li > p:nth-child(2){margin: 16px 0 8px 0; font-size: 18px;}
#introduction > .introduction_03 > ul > li > p:nth-child(3){color:#999;}
/* introduction_04 */
#introduction > .introduction_04{padding: 80px 0; background-color: #F2F6F6;  background: url(/public/images/png/img_visual01.png)no-repeat; background-position: center center; background-size: cover;}
#introduction > .introduction_04 > .txtBox{ margin-bottom:40px; }
#introduction > .introduction_04 > .txtBox > p, #introduction > .introduction_04 > .txtBox > p > span{color:#fff;}
#introduction > .introduction_04 > ul{display: grid; gap:130px; width: 944px; margin: 0 auto; grid-template-columns: 1fr 1fr 1fr;}
#introduction > .introduction_04 > ul > li{text-align: center;}
#introduction > .introduction_04 > ul > li > div > img{width:88px; height: 88px;}
#introduction > .introduction_04 > ul > li > p:nth-child(2){color:#fff; margin: 25px 0 8px 0;}
#introduction > .introduction_04 > ul > li > p:nth-child(3), .introduction_04 > ul > li > p:nth-child(3) > span{color:#fff; font-size: 32px; font-weight: 700;}
/* introduction_05 */
#introduction > .introduction_05{width: 944px; margin: 0 auto; padding: 80px 0; padding-bottom: 0 ;}
#introduction > .introduction_05 > .txtBox{ margin: 40px 0;}
#introduction > .introduction_05 > ul{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap:16px;}
#introduction > .introduction_05 > ul > li{text-align: center; background-color: #F3F7F7; border-radius: 16px; border: 1px solid rgba(0, 0, 0, 0.04); padding: 24px 16px ;}
#introduction > .introduction_05 > ul > li > img{width: 80px; height: 80px;}
#introduction > .introduction_05 > ul > li > p:nth-child(2){margin: 16px 0 8px 0; font-size: 18px;}
#introduction > .introduction_05 > ul > li > p:nth-child(3){color: #666;}
 
/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
#introduction{padding-left: 0; padding-right: 0;}
#introduction .subTitle{width:100%; padding: 0 32px;}
#introduction > .introduction_01{padding: 40px 32px; width: 100%; display: block; height: 329px; position: relative;}
#introduction > .introduction_01 .txtBox{position: absolute; top:50%; transform: translateY(-50%); z-index: 9;}
#introduction > .introduction_01 > img{position: absolute; right: 0; top:50%; transform: translateY(-50%); width: 434px;}
#introduction > .introduction_02{padding: 16px 32px 40px 32px; width: 100%;}
#introduction > .introduction_02 > ul > li{display: block; text-align: center;}
#introduction > .introduction_02 > ul > li > img{margin-bottom: 16px;}
#introduction > .introduction_03{padding: 40px 0 40px 0; width: 100%;}
#introduction > .introduction_03 > ul{ overflow-X: scroll; -ms-overflow-style: none; scrollbar-width: none; padding:0 20px;}
#introduction > .introduction_03 > ul > li{min-width: 224px;}
#introduction > .introduction_04{width: 100%;}
#introduction > .introduction_04 > ul{width: 100%; padding: 0 32px; gap:16px}
#introduction > .introduction_04 > ul > li{width:224px;}
#introduction > .introduction_05{padding: 40px 32px; width: 100%;}
#introduction > .introduction_05 > ul{grid-template-columns: 1fr 1fr;}
#introduction > .introduction_01 .txtBox > .txtSub{word-break: normal !important; margin: 16px 0 0 0;}
#introduction > .introduction_01 .txtBox > .txtSub br{display: block;}
}

@media all and (max-width:767px){
#introduction .subTitle{margin-bottom: 40px; padding: 0 20px;}
#introduction > .introduction_01{padding: 0 20px; flex-wrap: wrap; flex-direction: column-reverse; height: auto; display: flex; gap:24px; margin-bottom: 40px;}
#introduction > .introduction_01 > img{width: 100%; position: unset; transform: none;}
#introduction > .introduction_01 .txtBox{text-align: center; position: unset; transform: none;}
#introduction > .introduction_01 .txtBox > .txtSub{word-break: keep-all !important; margin-bottom: 0;}
#introduction > .introduction_01 .txtBox br{display: none;}
#introduction > .introduction_02 {padding: 40px 0 40px 0; width: 100%;}
#introduction > .introduction_02 > ul{display: grid; grid-template-columns: 1fr 1fr 1fr; overflow-X: scroll; -ms-overflow-style: none; scrollbar-width: none; padding:0 20px;}
#introduction > .introduction_02 > ul > li{min-width: 224px;}
#introduction > .introduction_02 > ul::-webkit-scrollbar {display: none; /* 크롬, 사파리, 오페라 */}
#introduction > .introduction_04{padding: 80px 20px 80px 20px; margin-top: 40px;}
#introduction > .introduction_04 .txtBox > .txtTitle{font-size: 24px;}
#introduction > .introduction_04 .txtBox > .txtTitle > span{font-size: 24px;}
#introduction > .introduction_04 > ul{display: block; text-align: center;}
#introduction > .introduction_04 > ul > li{margin-bottom: 16px; width: 100%; min-width: 224px;}
#introduction > .introduction_04 > ul > li:last-child{margin-bottom: 0;}
#introduction > .introduction_05{padding: 40px 20px 0 20px;}
#introduction > .introduction_05 .txtBox > .txtTitle{font-size: 24px; text-align: center;}

}