.policy table {border-collapse: collapse; margin: 8px 0;}

.policy h4{font-size: 20px; font-weight: 700;}
.policy h2:first-child{margin-top: 0;}
.policy h2{font-size: 18px; font-weight: 400; margin-bottom: 8px; margin-top: 24px;}
.policy .mini-title {
    font-size: large;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 8px;
}

.policy th, .policy td{
    text-align: left;
}

.policy p {color:#666; font-size: 14px;}
.policy a {color:#666; font-size: 14px;}
.policy li {
    margin-bottom: 8px;
}
/* Top-level list */
.policy ol.top-level {
    counter-reset: top-level;
}

.policy ol.top-level > li {
    counter-increment: top-level;
    list-style: none;
    padding-left: 2em;
    text-indent: -1em; color:#666; font-size: 14px;
}

.policy ol.top-level > li::before {
    content: counters(top-level, ".") ". ";
}

/* Nested ordered list with alphabet */
.policy ol.nested {
    margin-top: 10px;
    padding-left: 15px;
    counter-reset: nested;
}

.policy ol.nested > li {
    counter-increment: nested;
    list-style: none; color:#666; font-size: 14px;
}
.policy ol.nested > li a{color:#666; font-size: 14px;}
.policy ol.nested > li::before {
    content: counter(nested, lower-alpha) ". ";
}

/* Nested unordered list with alphabet */
.policy ul.nested {
    margin-top: 10px;
    padding-left: 15px;
    counter-reset: nested;
}

.policy ul.nested > li {
    list-style: disc inside; color:#666; font-size: 14px;
}

/* .policy .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 10px;
} */

.mgB8{margin-bottom: 8px;}

@media all and (max-width:767px){
    th{white-space: wrap;}
    .policy h4{display: none;}
    #agreement select{margin-top: 0;}
    .policy table th{white-space: wrap;}
}
