#notice{padding-top: 40px;}
#notice > .totalBox{margin-bottom: 24px;}
#notice > .totalBox > p{color:#999;}
#notice > .noticeCont > ul{display: grid; grid-template-columns: 1fr 1fr; gap:16px; cursor: pointer;}
#notice > .noticeCont > ul > li{border:1px solid #eaeaea; border-radius: 16px; padding: 16px; background-color: #fff; transition: all .3s; min-width: 344px;}
#notice > .noticeCont > ul > li:hover{box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08); transition: all .3s;}
#notice > .noticeCont > ul > li > .notiInfo{display: flex; justify-content: space-between; align-items: center;}
#notice > .noticeCont > ul > li > .notiInfo > .category{font-size: 11px; padding: 4px 6px; border-radius: 4px; font-weight: 700;}
#notice > .noticeCont > ul > li > .notiInfo > .categoryNoti{border:1px solid #7056F9; color:#7056F9; background-color: #EAE5FF;}
#notice > .noticeCont > ul > li > .notiInfo > .categoryIssue{border:1px solid #2FB3A4; color:#2FB3A4; background-color: #ECFAF8;}
#notice > .noticeCont > ul > li > .notiInfo > .no{font-size: 12px; color:#999;}
#notice > .noticeCont > ul > li > .notiTitle{margin: 10px 0 4px 0; overflow: hidden; text-overflow: ellipsis; display:-webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
#notice > .noticeCont > ul > li > .con > p{color:#666; line-height: 150%;}
#notice > .noticeCont > ul > li > .date{font-size: 12px; color:#999;}

/* ****************** 반응형 ********************** */
@media all and (max-width:1023px){
            
    #notice > .noticeCont > ul > li > .notiTitle > p{word-break: normal;}     
}

@media all and (max-width:767px){
    #notice > .noticeCont > ul{grid-template-columns: 1fr;}
    #notice > .noticeCont > ul > li{min-width: 100%;}
    
}