@charset "UTF-8";

* { margin: 0; padding: 0; box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; font-size: 14px; font-family: "Pretendard Variable", "Pretendard", "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", " sans-serif "," sans-serif" ; color: #222; font-weight: 400; }
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable.css");

.clearBox {   opacity: 0;  transition: opacity 0.3s ease;}
.fade-in { opacity: 1;}
.fade-out { opacity: 0;}

html, body, form { width: 100%; height: 100%; scroll-behavior: smooth;}
/*body { }*/
header, footer, section, aside, nav, article { display: block; }
input, select, div, textarea { vertical-align: middle; }
img { vertical-align: top; }
img, fieldset { border: none;  }
ul, ol, dl { list-style: none; }
strong { font-weight: 800; }
em { font-style: oblique; }
img{width:100%;}

/* Link Style */
a { text-decoration: none; color: #222;}
a:hover{ text-decoration: none; }
b{font-weight: 700;}
p{letter-spacing: -0.5px;}

/* From Style */
textarea { padding: 8px; resize: none; }
input, select, textarea {border: 1px solid #eaeaea; width: 100%; color: #222; padding: 0 16px; border-radius: 8px; height: 40px; }
input[type=image] { width: auto; border: none; }
select { padding:10px 16px; background:url(../../../public/images/btn/chevron-down_g.svg) no-repeat no-repeat 96% 50%/24px auto;
    /* 화살표 디자인 */
    -webkit-appearance:none; /* for chrome */ -moz-appearance:none; /*for firefox*/ appearance:none;}
select::-ms-expand{display:none;/*for IE10,11*/}
input[type=checkbox], input[type=radio] { width: 18px; height: 18px; border: 1px solid #e4e4e4; }
input[type=text], input[type=password] { padding: 0 16px; }
input[type=text]:focus, input[type=password]:focus, textarea:focus, select:focus, input[type=number]:focus {border: 1px solid #7056F9; outline: none; }
input[type=text]::-ms-clear { display:none; }
input[type=checkbox], input[type=radio], input[type=button], label { cursor: pointer; }
input[disabled], select[disabled] { background-color: #f2f2f2; cursor: not-allowed; }
input[readonly] { background-color: #f2f2f2; cursor: not-allowed; }
input[readonly].txtRight, input.txtRight, input[readonly].txtR, input.txtR, .txtR { text-align: right; }
input[readonly].txtC, input.txtC, .txtC { text-align: center !important; }
input[readonly].txtL, input.txtL, .txtL { text-align: left !important; }
input::-webkit-input-placeholder { color: #999; }
/* input starting stylings ------------------------------- */
.labelInput, .ibsInfo{position:relative; }
.ibsInfo > label {color:#999; font-weight:normal; position:absolute; pointer-events:none; left:16px; top:12px; transition:0.2s ease all; -moz-transition:0.2s ease all;  -webkit-transition:0.2s ease all;}
.labelInput > input:focus ~ label{top:-6px; background-color:#fff; padding:0 4px; font-size: 12px;}
.labelInput > input:valid ~ label {top:-6px; background-color:#fff; padding:0 4px; font-size: 12px;}
.clearBox{position: absolute; right:16px; top:8px;}
textarea::-webkit-input-placeholder { color: #999; }
input:-moz-placeholder { color: #999; }
textarea:-moz-placeholder { color: #999; }
input::-moz-placeholder { color: #999; }
textarea::-moz-placeholder { color: #999; }
input:-ms-input-placeholder { color: #999; }
textarea:-ms-input-placeholder { color: #999; }
input:-mos-input-placeholder { color: #999; }
input::placeholder { color: #999; } /* IE10 이상을 위한 css */
button{background-color: rgba(0, 0, 0, 0); color:inherit; border:none; padding: 0 !important; font: inherit;}

.required,input.required,select.required,textarea.required {border: 2px solid #1e8fbe;}

/* Table Style */

table { border-collapse: collapse; border-spacing: 0; width: 100%; text-align: center; }
table td img { vertical-align: middle; }
th { padding: 10px; white-space: nowrap; font-weight: normal;}
table th > span{color:#999;font-size: 12px; font-weight: normal;}
td { padding: 10px; word-break:break-all; }
td.nowrap { white-space:nowrap; }
th.thL, td.tdL { text-align: left; }
th.thC, td.tdC { text-align: center; }
th.thR, td.tdR { text-align: right; }
th.thChk { padding: 0; }

/* font size */
.ftSize10 { font-size: 10px; }
.ftSize11 { font-size: 11px; }
.ftSize12 { font-size: 12px; }
.ftSize13 { font-size: 13px; }
.ftSize14 { font-size: 14px; }
.ftSize16 { font-size: 16px; }
.ftSize18 { font-size: 18px; }
.ftSize20 { font-size: 20px; }
.ftSize24 { font-size: 24px; }
.ftSize28 { font-size: 28px; }
.ftNormal { font-weight: normal; }
.ftMedium { font-weight: 600; }
.ftBold { font-weight: 700; }

/*color*/
.colGray { color: #666; }
.colGray2 { color: #999; }
.colGray3 { color: #ccc; }
.colBlue { color: #0091ea; }
.colBlue2 { color: #0266c1; }
.colWhite { color: #FFFFFF; }
.colRed { color: #FB5555; }
.colGreen { color: #497375; }
.colGreen2 { color: #1fd11f; }
.colOrange { color: #f2cc15; }
.colOrange2 { color: #f9a825; }
.colBlack { color: #333; }
.colPink { color: #D66C7B; }
.colPurple { color: #7D2A8B; }
.colYellow { color: #abab13; }
.colWhite { color: #FFFFFF; }
.colBrown { color: #b75013; }
.colSky { color: #02a6a6; }
.colNavy { color: #074199; }
.colPoint { color: #6042F9 !important; }

/* margin */
.mg0 { margin: 0 !important; }
.mgT3 { margin-top: 3px; }
.mgT5 { margin-top: 5px; }
.mgT10 { margin-top: 10px; }
.mgT20 { margin-top: 20px; }
.mgT30 { margin-top: 30px; }
.mgB3 { margin-bottom: 3px; }
.mgB5 { margin-bottom: 5px; }
.mgB8 { margin-bottom: 8px !important; }
.mgB10 { margin-bottom: 10px; }
.mgB20 { margin-bottom: 20px; }
.mgB30 { margin-bottom: 30px; }
.mgTB5 { margin-top: 5px; margin-bottom: 5px; }
.mgTB10 { margin-top: 10px; margin-bottom: 10px; }
.mgTB20 { margin-top: 20px; margin-bottom: 20px; }
.mgRL10 { margin-right: 10px; margin-left: 10px;}
.mgRL20 { margin-right: 20px; margin-left: 20px;}
.mgR5 { margin-right: 5px; }
.mgR10 { margin-right: 10px; }
.mgR20 { margin-right: 20px; }
.mgL5 { margin-left: 5px; }
.mgL10 { margin-left: 10px; }
.mgL20 { margin-left: 20px; }
.mg10 { margin: 10px; }

/* padding */
.pdT3 { padding-top: 3px; }
.pdT5 { padding-top: 5px; }
.pdT10 { padding-top: 10px; }
.pdT20 { padding-top: 20px; }
.pdT30 { padding-top: 30px; }
.pdB5 { padding-bottom: 5px; }
.pdB10 { padding-bottom: 10px;}
.pdTB10 { padding-top: 10px; padding-bottom: 10px; }
.pdTB20 { padding-top: 20px; padding-bottom: 20px; }
.pdR20 { padding-right: 20px; }
.pdL5 { padding-left: 5px; }
.pdL10 { padding-left: 10px; }
.pdL20 { padding-left: 20px; }
.pd10 { padding: 10px; }

.btnCenter { display: block; text-align: center; }
.btnRight { display: block; text-align: right; }
.btnPointer { cursor: pointer; }

.text-ellipsis { display: table; table-layout: fixed; width: 100%; white-space: nowrap; max-width: 280px;}
.text-ellipsis > * { display: table-cell; overflow: hidden; text-overflow: ellipsis; line-height: 24px;}
.text-ellipsis.new > a > div { width: auto; }

.floatL{float:left;}
.floatR{float:right;}
.overBox{overflow: hidden;}

img.va-md { vertical-align: middle; }
img.va-bt { vertical-align: bottom; }
img.va-tp { vertical-align: top; }

/* button */
.btn > img{vertical-align: middle; width:24px; height: 24px; margin-right: 8px;}
.btn, a.btn { display: inline-block; width: auto; cursor: pointer; padding: 0 16px; vertical-align: middle; font-size: 14px; font-weight: 700; text-align: center; border-radius: 8px; height: 40px; box-sizing: border-box; line-height: 38px;}
.btn100, a.btn100 {width:100%; text-align: center; height: 48px; line-height: 48px;}
.btnS, a.btnS {text-align: center; font-size: 14px;}
.btnPoint, a.btnPoint { background-color: #6042F9; border:1px solid #6042F9; color: #fff; }
.btnPointLine, a.btnPointLine { background:#fff; border:1px solid #6042F9; color: #6042F9;}
.btnWhite, a.btnWhite { background-color: #fff; border: 1px solid #ddd; color: #707070; }
.btnNavy, a.btnNavy { background-color: #002157; border-color: #002157; color:#fff;}
.btnRed, a.btnRed { background-color: #FB5555; border:1px solid #FB5555; color:#fff;}
.btnRedLine, a.btnRedLine { background-color: #fff; border:1px solid #FB5555; color: #FB5555; }
.btnGreen, a.btnGreen { background-color: #4caf50; border-color: #4caf50; color:#fff;}
.btnGreenLine, a.btnGreenLine { background-color: #fff; border:1px solid #4caf50; color:#4caf50;}
.btnBlue, a.btnBlue { background-color: #0091ea; border:1px solid #0091ea; color:#fff;}
.btnOrange, a.btnOrange { background-color: #E79E26; border:1px solid #E79E26; color:#fff;}
.btnYellow, a.btnYellow { background-color: #FFDE41; border:1px solid #FFDE41; color:#000;}
.btnGray, a.btnGray { background-color: #F4F4F4; border:1px solid rgba(0, 0, 0, 0.08); color:#bbb;}
.btnGray2, a.btnGray2 { background-color: #F4F4F4; border:1px solid rgba(0, 0, 0, 0.08); color:#222;}
.btnBlueGreen, a.btnBlueGreen { background-color: #004d48; border-color: #004d48; color:#fff;}
.twoBtnBox{display: grid; gap:16px; grid-template-columns: 1fr 1fr;}

/* radius Box */
.radiusBox{padding: 4px 10px; border-radius: 15px;}
.radiusBoxS{padding: 2px 4px; border-radius: 10px;}

/* border */
.borderAll{border: 1px solid #ccc;}
.borderR{border-right: 1px solid #ccc;}
.borderL{border-left: 1px solid #ccc;}
.borderT{border-top: 1px solid #ccc !important;}
.borderB{border-bottom: 1px solid #ccc;}
.borderNone{border: 0 !important;}

@media all and (max-width:1023px){
    br{display: none;}
    p{word-break: keep-all;}
}