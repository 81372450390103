#inquiry{padding-top: 40px;}
.addSearch{margin-top: 24px;}
.addSearch > input{position: relative; padding-left: 50px !important; background-image: url(../../../../public/images/btn/search.svg); background-repeat: no-repeat; background-size: 24px 24px; background-position: 17px 8px;}
#inquiry > .totalBox{margin-bottom: 24px; display: flex; justify-content: space-between; align-items: center;}
#inquiry > .totalBox > p{color:#999;}
#inquiry > .inquiryCont > ul > li > div{border-bottom: 1px solid #eaeaea;}
#inquiry > .inquiryCont > ul > li .category{font-size: 11px; padding: 4px 6px; border-radius: 4px; font-weight: 700; min-width:36px; text-align: center;}
/* 질문 */
#inquiry > .inquiryCont > ul > li > .qusetion{display: flex; justify-content: space-between; transition: all .3s; padding: 14px 16px; cursor: pointer;}
#inquiry > .inquiryCont > ul > li > .qusetion > .qusetionTitle > p{line-height: 24px;}
#inquiry > .inquiryCont > ul > li > .qusetion > .qusetionTitle{display: flex; gap:8px; align-items: flex-start;}
#inquiry > .inquiryCont > ul > li > .qusetion > .qusetionTitle > .categoryQuestion{border:1px solid #FB5555; color:#FB5555; background-color: #FFECEC;}
#inquiry > .inquiryCont > ul > li > .qusetion > a{min-width: 24px; height: 24px;}
/* 답변 */
#inquiry > .inquiryCont > ul > li > .answer{display: flex; gap:8px; align-items: flex-start; background-color: #f8f8f8; padding: 14px 16px;}
#inquiry > .inquiryCont > ul > li > .answer > .categoryAnswer{border:1px solid #7056F9; color:#7056F9; background-color: #EAE5FF;}
#inquiry > .inquiryCont > ul > li > .answer > div *{font: revert ; text-decoration: revert; color: revert; word-break: normal;}
#inquiry > .inquiryCont > ul > li > .answer > div br{display: block;}
#inquiry > .inquiryCont > ul > li > .answer > div img {max-width:max-content !important;}
#inquiry > .inquiryCont > ul > li > .answer > div{line-height: 24px; flex-grow: 1;}
#inquiry > .inquiryCont > ul > li > .answer > div blockquote {border-left: 4px solid #ccc; margin-bottom: 5px; margin-top: 5px; padding-left: 16px;}

@media all and (max-width:767px){
    #inquiry > .inquiryCont > ul > li > .answer > div img {object-fit: contain;}
    #inquiry{padding: 0;}
    #inquiry .tabBox > ul{justify-content: left; padding: 0 20px;}
    #inquiry > .subTitle{padding: 40px 20px 0 20px;}
    #inquiry > div:nth-child(3){padding: 0 20px;}
    #inquiry .pagingDiv{padding-bottom: 40px;}
}